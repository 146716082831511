import { Component, Inject, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { ForumService } from '../../services/forum.service';
import { SubthemeService } from '../../services/subtheme.service';
import { ThemeService } from '../../services/theme.service';
import { SubjectService } from '../../services/subject.service';
import { PostService } from '../../services/post.service';

@Component({
    selector: 'app-warning',
    templateUrl: './warning.component.html',
    styleUrls: ['./warning.component.scss']
})
export class WarningComponent implements OnDestroy {
    constructor(
        public dialogRef: MatDialogRef<WarningComponent>,
        private forumService: ForumService,
        private subthemeService: SubthemeService,
        private themeService: ThemeService,
        private subjectService: SubjectService,
        private postService: PostService,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {}

    @Output() openErrorDialog: EventEmitter<string> = new EventEmitter();

    private subscriptions = new Subscription();

    /**
     * Détermine le message à afficher dans le bandeau
     * @returns {String} Le message à afficher dans le bandeau
     */
    getHeader(): String {
        if (this.data.warning === 'DISABLE_FORUM') {
            return "Désactivation d'un forum";
        } else if (this.data.warning === 'DELETE_FORUM') {
            return "Suppression d'un forum";
        } else if (this.data.warning === 'DELETE_THEME') {
            return "Suppression d'un thème";
        } else if (this.data.warning === 'DELETE_SUBTHEME') {
            return "Suppression d'un sous-thème";
        } else if (this.data.warning === 'DELETE_SUBJECT') {
            return "Suppression d'un sujet";
        } else if (this.data.warning === 'DELETE_POST') {
            return "Suppression d'un post";
        }
    }

    /**
     * Détermine le message à afficher dans le corps de la fenêtre
     * @returns {String} Le message à afficher dans le corps de la fenêtre
     */
    getMainMessage(): String {
        if (this.data.warning === 'DISABLE_FORUM') {
            return 'Vous vous apprêtez à archiver ce forum.<br>Il ne sera plus visible que par vous.';
        } else if (this.data.warning === 'DELETE_FORUM') {
            return "Vous vous apprêtez à supprimer ce forum.<br><strong>Tous les éléments qu'il contient seront définitivement supprimés.</strong>";
        } else if (this.data.warning === 'DELETE_THEME') {
            return "Vous vous apprêtez à supprimer ce thème.<br><strong>Tous les éléments qu'il contient seront définitivement supprimés.</strong>";
        } else if (this.data.warning === 'DELETE_SUBTHEME') {
            return "Vous vous apprêtez à supprimer ce sous-thème.<br><strong>Tous les éléments qu'il contient seront définitivement supprimés.</strong>";
        } else if (this.data.warning === 'DELETE_SUBJECT') {
            return "Vous vous apprêtez à supprimer ce sujet.<br><strong>Tous les éléments qu'il contient seront définitivement supprimés.</strong>";
        } else if (this.data.warning === 'DELETE_POST') {
            return 'Vous vous apprêtez à supprimer ce post.<br><strong>Il sera définitivement supprimé.</strong>';
        }
    }

    /**
     * Détermine le message à afficher dans le bouton
     * @returns {String} Le message à afficher dans le bouton
     */
    getFooter(): String {
        if (this.data.warning === 'DISABLE_FORUM') {
            return 'Désactiver le forum';
        } else if (this.data.warning === 'DELETE_FORUM') {
            return 'Supprimer définitivement le forum';
        } else if (this.data.warning === 'DELETE_THEME') {
            return 'Supprimer définitivement le thème';
        } else if (this.data.warning === 'DELETE_SUBTHEME') {
            return 'Supprimer définitivement le sous-thème';
        } else if (this.data.warning === 'DELETE_SUBJECT') {
            return 'Supprimer définitivement le sujet';
        } else if (this.data.warning === 'DELETE_POST') {
            return 'Supprimer définitivement le post';
        }
    }

    /**
     * @param {Event} $event L'event Javascript émis lors du clic
     * Supprime le forum actuel
     */
    action() {
        if (this.data.warning === 'DISABLE_FORUM') {
            this.subscriptions.add(
                this.forumService.disableForum(this.data.warningId).subscribe(
                    (data: any) => {
                        this.forumService.emitDisabledForum(this.data.warningId);
                        this.closeDialog();
                    },
                    (error: HttpErrorResponse) => {
                        this.openErrorDialog.emit(error.error.userMessage);
                    }
                )
            );
        }
        if (this.data.warning === 'DELETE_FORUM') {
            this.subscriptions.add(
                this.forumService.deleteForum(this.data.warningId).subscribe(
                    (data: any) => {
                        this.forumService.emitDeletedForum(this.data.warningId);
                        this.closeDialog();
                    },
                    (error: HttpErrorResponse) => {
                        this.openErrorDialog.emit(error.error.userMessage);
                    }
                )
            );
        }
        if (this.data.warning === 'DELETE_THEME') {
            this.subscriptions.add(
                this.themeService.deleteTheme(this.data.warningId).subscribe(
                    (data: any) => {
                        this.themeService.emitDeletedTheme(this.data.warningId);
                        this.closeDialog();
                    },
                    (error: HttpErrorResponse) => {
                        this.openErrorDialog.emit(error.error.userMessage);
                    }
                )
            );
        }
        if (this.data.warning === 'DELETE_SUBTHEME') {
            this.subscriptions.add(
                this.subthemeService.deleteSubtheme(this.data.warningId).subscribe(
                    (data: any) => {
                        this.subthemeService.emitDeletedSubtheme(this.data.warningId);
                        this.closeDialog();
                    },
                    (error: HttpErrorResponse) => {
                        this.openErrorDialog.emit(error.error.userMessage);
                    }
                )
            );
        }
        if (this.data.warning === 'DELETE_SUBJECT') {
            this.subscriptions.add(
                this.subjectService.deleteSubject(this.data.warningId).subscribe(
                    (data: any) => {
                        this.subjectService.emitDeletedSubject(this.data.warningId);
                        this.closeDialog();
                    },
                    (error: HttpErrorResponse) => {
                        this.openErrorDialog.emit(error.error.userMessage);
                    }
                )
            );
        }
        if (this.data.warning === 'DELETE_POST') {
            this.subscriptions.add(
                this.postService.deletePost(this.data.warningId).subscribe(
                    (data: any) => {
                        this.postService.emitDeletedPost(this.data.warningId);
                        this.closeDialog();
                    },
                    (error: HttpErrorResponse) => {
                        this.openErrorDialog.emit(error.error.userMessage);
                    }
                )
            );
        }
    }

    /**
     * Ferme la fenêtre d'erreur
     */
    closeDialog() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
