<div class="header">
    <span class="icon-easi-forum-line"></span>
    <span class="label" [innerHTML]="headerText"></span>
    <span class="icon-close" (click)="closeDialog()"></span>
</div>
<mat-dialog-content class="content">
    <ng-container *ngIf="isLoaded">
        <div class="content-header">
            <span class="forum-count"
                ><b>{{ forums?.length }}</b> forums</span
            >
        </div>
        <div class="top">
            <span class="name">Nom</span>
            <span class="author">Auteur</span>
            <span class="members">Inscrits</span>
        </div>
        <div class="forum-container">
            <app-shared-forum-entry
                *ngFor="let forum of forums"
                [forum]="forum"
                (click)="openSharedForum(forum)"
            >
            </app-shared-forum-entry>
            <div class="no-shared-forums" *ngIf="forums.length === 0">
                Aucun forum partagé avec
                {{ data.type === 'group' ? 'ce groupe' : 'cet utilisateur' }}
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!isLoaded">
        <div class="loadingEntry">
            <div class="point1 point"></div>
            <div class="point2 point"></div>
            <div class="point3 point"></div>
        </div>
    </ng-container>
</mat-dialog-content>
<div class="footer">
    <div class="button" (click)="openCreateForum()">Créer un nouvel espace</div>
</div>
