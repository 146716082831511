<div class="header">
    <span class="label">{{ headerLabel }}</span>
    <span class="icon-close" (click)="closeDialog()"></span>
</div>
<mat-dialog-content class="content">
    <div class="top">
        <div class="subject-container">
            <span class="icon-Messages-cercle"></span>
            <span class="subject-title">{{ subject.title }}</span>
        </div>
    </div>
    <div class="label">Déplacer vers</div>
    <div class="main-content">
        <div class="forum-container">
            <div
                class="forum"
                [ngClass]="{
                    purple:
                        isInitialPosition(forum?.id, 'forum') || isNewPosition(forum?.id, 'forum'),
                    disabled: !canMoveSubjectInForum()
                }"
            >
                <span
                    (click)="selectNewPosition(forum?.id, 'forum')"
                    [ngClass]="{
                        'icon-Valide': isNewPosition(forum?.id, 'forum'),
                        'icon-select': !isNewPosition(forum?.id, 'forum')
                    }"
                ></span>
                <span class="icon-easi-forum-line"></span>
                <span class="forum-title">{{ forum?.name }}</span>
            </div>
        </div>
        <div class="theme-container">
            <div
                class="theme"
                [ngClass]="{
                    purple: isInitialPosition(theme.id, 'theme') || isNewPosition(theme.id, 'theme')
                }"
                *ngFor="let theme of forum?.themes"
            >
                <span
                    (click)="selectNewPosition(theme?.id, 'theme')"
                    [ngClass]="{
                        'icon-Valide': isNewPosition(theme?.id, 'theme'),
                        'icon-select': !isNewPosition(theme?.id, 'theme')
                    }"
                ></span>
                <span class="icon-theme"></span>
                <span class="theme-title">{{ theme.title }}</span>
                <div class="separator" *ngIf="theme.subthemes.length > 0"></div>
                <div class="subtheme-container" *ngIf="theme.subthemes.length > 0">
                    <div
                        class="subtheme"
                        [ngClass]="{
                            purple:
                                isInitialPosition(subtheme.id, 'subtheme') ||
                                isNewPosition(subtheme.id, 'subtheme')
                        }"
                        *ngFor="let subtheme of theme?.subthemes"
                    >
                        <span
                            (click)="selectNewPosition(subtheme?.id, 'subtheme')"
                            [ngClass]="{
                                'icon-Valide': isNewPosition(subtheme?.id, 'subtheme'),
                                'icon-select': !isNewPosition(subtheme?.id, 'subtheme')
                            }"
                        ></span>
                        <span class="icon-Repertoire-plein"></span>
                        <span class="subtheme-title">{{ subtheme.title }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<div class="footer">
    <div
        class="create-button"
        [ngClass]="{ disabled: !canMoveSubject() }"
        (click)="moveSubject()"
        *ngIf="!moving"
    >
        {{ footerLabel }}
    </div>
    <div class="create-button" *ngIf="moving">
        <div class="loadingEntry">
            <div class="point1 point"></div>
            <div class="point2 point"></div>
            <div class="point3 point"></div>
        </div>
    </div>
</div>
