<div class="header">
    <span class="icon-easi-forum-line"></span>
    <span class="label">{{ headerLabel }}</span>
    <span class="icon-close" (click)="closeDialog()"></span>
</div>
<mat-dialog-content class="content">
    <mat-form-field>
        <div class="name">
            <mat-label class="label">Nom</mat-label>
            <input
                matInput
                class="value"
                type="text"
                placeholder="Nom du forum"
                [(ngModel)]="forum.name"
            />
        </div>
        <div class="description">
            <div class="label pointer" *ngIf="!showDescription" (click)="toggleDescription()">
                Ajouter une description
            </div>
            <div class="label" *ngIf="showDescription">Description</div>
            <textarea
                class="value"
                *ngIf="showDescription"
                placeholder="Description"
                [(ngModel)]="forum.description"
            ></textarea>
        </div>
    </mat-form-field>
    <div class="separator"></div>
    <div class="members">
        <div class="nav-container">
            <div
                class="label"
                [ngClass]="{ full: !showRoleNav(), selected: isCurrentNav('users') }"
                (click)="switchCurrentNav('users')"
            >
                Participants
            </div>
            <div
                class="label"
                *ngIf="showRoleNav()"
                [ngClass]="{ selected: isCurrentNav('roles') }"
                (click)="switchCurrentNav('roles')"
            >
                Rôles
            </div>
        </div>
        <div class="users-container" *ngIf="isCurrentNav('users')">
            <div class="top">
                <div class="tab-switch">
                    <div
                        class="learner-switch"
                        [ngClass]="{ 'low-opacity': !isCurrentTab('learner') }"
                        (click)="switchCurrentTab('learner')"
                    >
                        {{ getCurrentLearnerNumber() }}/{{ totalLearnerNumber }} Apprenants
                    </div>
                    <div
                        class="learner-group-switch"
                        [ngClass]="{ 'low-opacity': !isCurrentTab('learner-group') }"
                        *ngIf="showGroupTab()"
                        (click)="switchCurrentTab('learner-group')"
                    >
                        {{ getCurrentLearnerGroupNumber() }}/{{ totalLearnerGroupNumber }} Groupes
                        d'apprenants
                    </div>
                    <div
                        class="teacher-switch"
                        [ngClass]="{ 'low-opacity': !isCurrentTab('teacher') }"
                        *ngIf="showTeacherTab()"
                        (click)="switchCurrentTab('teacher')"
                    >
                        {{ getCurrentTeacherNumber() }}/{{ totalTeacherNumber }} Formateurs
                    </div>
                    <div
                        class="teacher-group-switch"
                        [ngClass]="{ 'low-opacity': !isCurrentTab('teacher-group') }"
                        *ngIf="showTeacherTab() && showGroupTab()"
                        (click)="switchCurrentTab('teacher-group')"
                    >
                        {{ getCurrentTeacherGroupNumber() }}/{{ totalTeacherGroupNumber }} Groupes
                        de formateurs
                    </div>
                </div>
                <div class="structure-container" *ngIf="isNationalAdmin() && structures">
                    <lib-uimm-dropdown
                        [(items)]="structures"
                        (itemsChange)="updateStructure($event)"
                        [label]="'Toutes les structures'"
                        [labelWidth]="'175px'"
                        [maxWidth]="250"
                        [maxHeight]="250"
                        [design]="'boxes'"
                        [headers]="{ search: true }"
                    ></lib-uimm-dropdown>
                </div>
                <div class="search-container">
                    <div class="search-input-container">
                        <input
                            class="input-container"
                            type="text"
                            placeholder="Chercher..."
                            [(ngModel)]="searchTerm"
                            (keyup.enter)="refreshData(true)"
                        />
                        <span class="icon-search" (click)="refreshData(true)"></span>
                    </div>
                </div>
            </div>
            <div
                class="learner-group-container"
                infiniteScroll
                [infiniteScrollDistance]="2"
                [infiniteScrollThrottle]="50"
                [scrollWindow]="false"
                (scrolled)="nextPageLearnerGroup(false)"
                *ngIf="isCurrentTab('learner-group')"
            >
                <app-create-forum-entry
                    *ngFor="let group of learnerGroups"
                    [element]="group"
                    [public]="forum.public"
                    [(selected)]="group.selected"
                    [(role)]="group.role"
                ></app-create-forum-entry>
            </div>
            <div
                class="teacher-group-container"
                infiniteScroll
                [infiniteScrollDistance]="2"
                [infiniteScrollThrottle]="50"
                [scrollWindow]="false"
                (scrolled)="nextPageLearnerGroup(false)"
                *ngIf="isCurrentTab('teacher-group')"
            >
                <app-create-forum-entry
                    *ngFor="let group of teacherGroups"
                    [element]="group"
                    [public]="forum.public"
                    [(selected)]="group.selected"
                    [(role)]="group.role"
                ></app-create-forum-entry>
            </div>
            <div
                class="learner-container"
                infiniteScroll
                [infiniteScrollDistance]="2"
                [infiniteScrollThrottle]="50"
                [scrollWindow]="false"
                (scrolled)="nextPageLearner(false)"
                *ngIf="isCurrentTab('learner')"
            >
                <app-create-forum-entry
                    *ngFor="let learner of learners"
                    [element]="learner"
                    [public]="forum.public"
                    [(selected)]="learner.selected"
                    [(role)]="learner.role"
                ></app-create-forum-entry>
            </div>
            <div
                class="teacher-container"
                infiniteScroll
                [infiniteScrollDistance]="2"
                [infiniteScrollThrottle]="50"
                [scrollWindow]="false"
                (scrolled)="nextPageTeacher(false)"
                *ngIf="isCurrentTab('teacher')"
            >
                <app-create-forum-entry
                    *ngFor="let teacher of teachers"
                    [element]="teacher"
                    [public]="forum.public"
                    [(selected)]="teacher.selected"
                    [(role)]="teacher.role"
                ></app-create-forum-entry>
            </div>
        </div>
        <div
            class="roles-container"
            *ngIf="isCurrentNav('roles')"
            infiniteScroll
            [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="50"
            [scrollWindow]="false"
        >
            <app-create-forum-entry
                *ngFor="let role of roles"
                [element]="role"
                [(selected)]="role.selected"
                [(role)]="role.role"
            ></app-create-forum-entry>
        </div>

        <div class="resume-members" *ngIf="isCurrentNav('users')">
            Vous avez inscrit <b>{{ getTotalCurrentLearnerGroupNumber() }}</b> groupe(s),
            <b>{{ getTotalCurrentTeacherGroupNumber() }}</b> groupe(s) de formateurs,
            <b>{{ getTotalCurrentLearnerNumber() }}</b> apprenant(s) et
            <b>{{ getTotalCurrentTeacherNumber() }}</b> formateur(s)
        </div>
        <div class="resume-members" *ngIf="isCurrentNav('roles')">
            Vous avez inscrit <b>{{ getCurrentRoleNumber() }}</b> rôle(s)
        </div>
    </div>
</mat-dialog-content>
<div class="footer">
    <div
        class="create-button"
        [ngClass]="{ disabled: !canCreateForum() }"
        (click)="createForum()"
        *ngIf="!creationDone"
    >
        {{ footerLabel }}
    </div>
    <div class="create-button" *ngIf="creationDone">
        <div class="loadingEntry">
            <div class="point1 point"></div>
            <div class="point2 point"></div>
            <div class="point3 point"></div>
        </div>
    </div>
</div>
