import { Component, Inject, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ForumService } from '../../services/forum.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-mask-forum',
    templateUrl: './mask-forum.component.html',
    styleUrls: ['./mask-forum.component.scss']
})
export class MaskForumComponent implements OnDestroy {
    /**
     * @param {EventEmitter} openErrorDialog emet un evenement si il faut ouvrir la fenetre d'erreur.
     * @param {any} forum le forum en question.
     * @param {number} groupNumber le nombre de groupes inscrits au forum.
     * @param {number} learnerNumber le nombre d'apprenants inscrits au forum.
     * @param {number} teacherNumber le nombre de tuteurs inscrits au forum.
     */
    @Output() openErrorDialog: EventEmitter<string> = new EventEmitter();

    forum: any;

    groupNumber: number;
    learnerNumber: number;
    teacherNumber: number;
    roleNumber: number;

    private subscriptions = new Subscription();

    constructor(
        public dialogRef: MatDialogRef<MaskForumComponent>,
        private forumService: ForumService,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {}

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    /**
     * Ferme la fenetre.
     */
    closeDialog() {
        this.dialogRef.close();
    }

    maskForum() {
        this.subscriptions.add(
            this.forumService.maskForum(this.data.forum.id).subscribe(
                (data: any) => {
                    this.forumService.emitMaskedForum(this.data.forum);
                    this.closeDialog();
                },
                (error: HttpErrorResponse) => {
                    this.openErrorDialog.emit(error.error.userMessage);
                }
            )
        );
    }
}
