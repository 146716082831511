import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { ForumService } from '../../../services/forum.service';
import { UsersService } from '../../../services/users.service';
import { DialogService } from '../../../services/dialog.service';
import { LoginService } from '../../../services/login.service';
import { slideToggle } from 'src/app/animations/slide-toggle.animation';

/**
 * Composant gérant l'affichage des forums dans la fenêtre d'enregistrement des forums
 */
@Component({
    selector: 'app-subscribe-forum-entry',
    templateUrl: './subscribe-forum-entry.component.html',
    styleUrls: ['./subscribe-forum-entry.component.scss'],
    animations: [slideToggle]
})
export class SubscribeForumEntryComponent implements OnInit, OnDestroy {
    @Input() forum: any;
    @Input() selected: boolean;

    @Output() select: EventEmitter<any> = new EventEmitter();

    openedForum: boolean;

    getGroup$: Subscription;
    private subscriptions = new Subscription();

    constructor(
        private forumService: ForumService,
        private userService: UsersService,
        private dialogService: DialogService,
        private loginService: LoginService
    ) {}

    ngOnInit() {
        this.openedForum = false;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    /**
     * @param {any} user Un membre du forum actuel
     * Indique l'icône à afficher selon le type et le rôle de l'utilisateur
     * @returns {string} La classe CSS correspondante à l'icône à afficher
     */
    getUserIcon(user: any): string {
        if (user.roles.learner) {
            return 'icon-apprenant';
        } else if (user.roles.prospect) {
            return 'icon-apprenant-prospect';
        } else if (user.roles.internalTeacher) {
            return 'icon-formateurinterne';
        } else if (user.roles.externalTeacher) {
            return 'icon-formateurexterne';
        } else if (user.roles.siteTeacher) {
            return 'icon-icon-formateur-site';
        } else if (user.roles.corporationTeacher) {
            return 'icon-FormateurEntreprise';
        } else if (user.roles.tuteurentreprise) {
            return 'icon-tuteurentreprise';
        } else if (user.roles.localAdmin) {
            return 'icon-adminlocal';
        } else if (user.roles.nationalAdmin) {
            return 'icon-adminnational';
        }
    }

    /**
     * Indique le nom de l'auteur du forum
     * @returns {string} Le prénom et le nom de l'auteur du forum
     */
    getAuthorName(): string {
        return this.forum.owner.lastname.toUpperCase() + ' ' + this.forum.owner.firstname;
    }

    /**
     * Envoie les informations du forum au composant parent
     */
    emitEvent() {
        const data = {
            selected: this.selected,
            id: this.forum.id
        };
        this.select.emit(data);
    }

    /**
     * Sélectionne/déselectionne le forum
     */
    toggleSelected() {
        this.selected = !this.selected;
        this.emitEvent();
    }

    /**
     * Indique si le forum actuel est sélectionné
     * @returns {Boolean} True si le forum actuel est sélectionné, faux dans le cas contraire
     */
    isForumSelected(): boolean {
        return this.selected;
    }

    /**
     * Indique si le forum actuel est ouvert
     * @returns {Boolean} True si le forum actuel est ouvert, faux dans le cas contraire
     */
    isForumOpened(): boolean {
        return this.openedForum;
    }

    canOpenforum(): boolean {
        return this.loginService.isAdmin();
    }

    /**
     * @param {Event} $event L'évenement JS émis lors du clic
     * Ouvre et affiche le forum actuel
     */
    showForum($event: Event) {
        if (this.forum.users === undefined) {
            this.subscriptions.add(
                this.forumService.getForum(this.forum.id).subscribe(
                    (data: any) => {
                        for (const i in data.users) {
                            if (data.users[i].id === this.forum.owner.id) {
                                data.users.splice(i, 1);
                            }
                        }
                        this.forum.users = data.users;
                        this.forum.groups = data.groups;

                        this.openedForum = !this.openedForum;
                        for (const i in data.groups) {
                            if (data.groups[i].users === undefined) {
                                if (this.getGroup$) {
                                    this.getGroup$.unsubscribe();
                                }
                                this.getGroup$ = this.userService
                                    .getGroup(data.groups[i].id)
                                    .subscribe(
                                        (data_group: any) => {
                                            data.groups[i].users = data_group.users;
                                        },
                                        (error: HttpErrorResponse) => {
                                            this.dialogService.openErrorDialog(
                                                error.error.userMessage
                                            );
                                        }
                                    );
                                this.subscriptions.add(this.getGroup$);
                            }
                        }
                    },
                    (error: HttpErrorResponse) => {
                        this.dialogService.openErrorDialog(error.error.userMessage);
                    }
                )
            );
        } else {
            this.openedForum = !this.openedForum;
        }
    }
}
