import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { Theme } from '../structures/theme';
import { log } from './decorators/log.decorator';

/**
 * Service gérant tous les appels aux webservice liés aux thèmes
 */
@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    constructor(private http: HttpClient) {}

    refreshCurrentTheme: Subject<string> = new Subject();
    movedUpTheme: Subject<Theme> = new Subject();
    movedDownTheme: Subject<Theme> = new Subject();
    createdTheme: Subject<Theme> = new Subject();
    deletedTheme: Subject<number> = new Subject();

    /**
     * @param {any} theme Un objet contenant 2 paramètres : L'id du forum dans lequel on souhaite créer le theme, et le nom du theme
     * Crée un theme
     * @returns {Observable} Un observable d'un booléen indiquant si la création du theme s'est déroulé correctement
     */
    @log() createTheme(body: any): Observable<any> {
        return this.http.post('/themes', body);
    }

    /**
     * @param {any} theme Un objet contenant 2 paramètres : L'id du forum dans lequel on souhaite modifier le theme, et le nom du theme
     * Modifie un theme
     * @returns {Observable} Un observable d'un booléen indiquant si la modification du theme s'est déroulé correctement
     */
    @log() updateTheme(body: any): Observable<any> {
        return this.http.put('/themes/' + body.id, body);
    }

    /**
     * @param {number} id L'identifiant du theme à récuperer
     * @param {string} searchTerm Une chaîne de caractère optionnel permettant de filtrer les sujets contenu dans le thème
     * Récupère un thème
     * @returns {Observable} Un observable du theme récupéré
     */
    @log() getTheme(id: number, searchTerm?: string): Observable<any> {
        const params: any = {};

        if (searchTerm) {
            params.search = searchTerm;
        }

        return this.http.get('/themes/' + id, { params });
    }

    /**
     * @param {number} id L'identifiant du theme à supprimer
     * Supprime un thème
     * @returns {Observable} Un observable d'un booléen indiquant si la suppression du theme s'est déroulé correctement
     */
    @log() deleteTheme(id: number): Observable<any> {
        return this.http.delete('/themes/' + id);
    }

    /**
     * @param {number} id L'identifiant du theme à remonter
     * Remonte un theme d'une position
     * @returns {Observable} Un observable du theme déplacé
     */
    @log() moveUpTheme(id: number): Observable<any> {
        return this.http.put('/themes/' + id + '/up', {});
    }

    /**
     * @param {number} id L'identifiant du theme à descendre
     * Descend un theme  d'une position
     * @returns {Observable} Un observable du theme déplacé
     */
    @log() moveDownTheme(id: number): Observable<any> {
        return this.http.put('/themes/' + id + '/down', {});
    }

    /**
     * Un event récupérable par les composants permettant de mettre à jour le thème actuel
     */
    refreshTheme(searchTerm?: string) {
        this.refreshCurrentTheme.next(searchTerm);
    }

    emitMovedUpTheme(theme: Theme) {
        this.movedUpTheme.next(theme);
    }

    emitMovedDownTheme(theme: Theme) {
        this.movedDownTheme.next(theme);
    }

    emitCreatedTheme(theme: Theme) {
        this.createdTheme.next(theme);
    }

    emitDeletedTheme(themeId: number) {
        this.deletedTheme.next(themeId);
    }
}
