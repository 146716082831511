<div class="container" routerLink="/theme/{{ theme.id }}" [ngClass]="{ bold: !isViewedTheme() }">
    <span class="icon-theme"></span>
    <div class="title">
        <span class="name" *ngIf="!editing">{{ theme.title }}</span>
        <div *ngIf="editing">
            <input
                #inputTitle
                class="input-title"
                [(ngModel)]="theme.title"
                (keyup.enter)="renameTheme($event)"
                (keyup.escape)="cancelEditTheme()"
                (click)="$event.stopPropagation()"
            />
            <span class="icon-Envoyer" (click)="renameTheme($event)"></span>
        </div>
    </div>
    <div class="subjects-count">
        <span>{{ getPostsCount() }}</span>
    </div>
    <div class="blank"></div>
    <div class="last-message-author" *ngIf="theme.last">
        <span class="icon" [ngClass]="getRoleIcon()"></span>
        <span class="name">{{ getThemeLastMessageAuthor() }}</span>
    </div>
    <div class="last-message-date" [ngClass]="{ toggle: showIcons() }">
        {{ getThemeLastMessageDate() | date: 'dd/MM/yyyy' }}
        {{ getThemeLastMessageDate() | date: 'HH:mm' }}
    </div>
    <div class="icons">
        <span
            class="icon-Monter"
            *ngIf="canMoveTheme()"
            [ngClass]="{ disabled: !canMoveUpTheme() }"
            (click)="moveUpTheme($event)"
            tooltip="Remonter"
            tooltipPosition="left"
            tooltipTextColor="#955099"
            tooltipColor="#fff"
        ></span>
        <span
            class="icon-Descendre"
            *ngIf="canMoveTheme()"
            [ngClass]="{ disabled: !canMoveDownTheme() }"
            (click)="moveDownTheme($event)"
            tooltip="Descendre"
            tooltipPosition="left"
            tooltipTextColor="#955099"
            tooltipColor="#fff"
        ></span>
        <span
            class="icon-Editer"
            *ngIf="canEditTheme()"
            (click)="editTheme($event)"
            tooltip="Renommer le thème"
            tooltipPosition="left"
            tooltipTextColor="#955099"
            tooltipColor="#fff"
        ></span>
        <span
            class="icon-bin"
            *ngIf="canDeleteTheme()"
            (click)="deleteTheme($event)"
            tooltip="Supprimer le thème"
            tooltipPosition="left"
            tooltipTextColor="#955099"
            tooltipColor="#fff"
        ></span>
    </div>
</div>
