import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, fromEvent } from 'rxjs';
import { tap, filter } from 'rxjs/operators';

import { LoginService } from './login.service';
import { Structure } from '../structures/structure';
import { EasiHttpParams } from '../interceptors/easi-http-params';
import { log } from './decorators/log.decorator';

/**
 * Service gérant tous les appels aux webservice liés aux utilisateurs/groupes
 */
@Injectable({
    providedIn: 'root'
})
export class UsersService {
    constructor(private http: HttpClient, private loginService: LoginService) {}

    structuresList: Array<Structure>;

    /**
     * Récupère la liste des structures
     * @returns {Observable} Un observable de la liste des structures
     */
    @log() getStructures(): Observable<any> {
        const iframe: any = document.getElementById('header-container');
        iframe.contentWindow.postMessage('getStructures', '*');

        return fromEvent(window, 'message').pipe(
            filter((data: any) => {
                return data.data.structures !== undefined;
            }),
            tap(
                (data) => {
                    this.structuresList = data.data.structures;
                },
                (error) => {
                    this.loginService.isAuthenticated(error);
                }
            )
        );
    }

    /**
     * Renvoie la liste des structures
     */
    getStructuresList(): Array<Structure> {
        return this.structuresList;
    }

    /**
     * @param {number} id L'identifiant du groupe que l'on souhaite récuperer
     * Récupère un groupe spécifique
     * @returns {Observable} Un observable du groupe récupéré
     */
    @log() getGroup(id: number): Observable<any> {
        return this.http.get('/groups/' + id);
    }

    /**
     * @param {any} params Un objet contenant 2 paramètres : structureid et search
     * Récupère une liste de groupe
     * @returns {Observable} Un observable des groupes correspondant aux paramètre
     */
    @log() getGroups(params: any): Observable<any> {
        return this.http.get('/groups', { params });
    }

    /**
     * @param {any} params Un objet contenant 2 paramètres : structureid
     * Récupère le nombre de groupes correspondants aux paramètres sélectionnés
     * @returns {Observable} Un observable des groupes correspondant aux paramètre
     */
    @log() getGroupsCount(params: any): Observable<any> {
        params.count = true;
        return this.http.get('/groups?count=true', { params });
    }

    /**
     * @param {any} params Un objet contenant 2 paramètres : role et structureid
     * Récupère une liste de groupe
     * @returns {Observable} Un observable des groupes correspondant aux paramètre
     */
    @log() getUsers(params: any): Observable<any> {
        return this.http.get('/users', { params });
    }

    /**
     * @param {any} params Un objet contenant 1 paramètre : role et structureid
     * Récupère le nombre d'utilisateurs correspondant aux paramètres
     * @returns {Observable} Un observable des groupes correspondant aux paramètre
     */
    @log() getUsersCount(params: any): Observable<any> {
        return this.http.get('/users?count=true', { params });
    }

    /**
     * @param {number} userId L'identifiant de l'utilisateur que l'on souhaite récuperer
     * Récupère un utilisateur spécifique
     * @returns {Observable} Un observable de l'utilisateur récupéré
     */
    @log() getUser(userId: number): Observable<any> {
        return this.http.get('/users/' + userId);
    }

    /**
     * Récupère la liste des rôles de la plateforme
     * @returns {Observable} Un observable de l'utilisateur récupéré
     */
    @log() getRoles(): Observable<any> {
        return this.http.get('/roles');
    }
}
