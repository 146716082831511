import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { Post } from '../structures/post';
import { log } from './decorators/log.decorator';

/**
 * Service gérant tous les appels aux webservice liés aux posts
 */
@Injectable({
    providedIn: 'root'
})
export class PostService {
    constructor(private http: HttpClient) {}

    deletedPost: Subject<number> = new Subject();
    updatedPost: Subject<Post> = new Subject();

    /**
     * @param { number } postId L'id du post que l'on souhaite récupérer
     * Récupère un post
     * @returns {Observable} Un observable d'un post
     */
    @log() getPost(postId: number): Observable<any> {
        return this.http.get('/posts/' + postId);
    }

    /**
     * @param {any} body Un objet contenant 2 paramètres : L'id du sujet dans lequel on souhaite créer le post, et le contenu du post
     * Crée un post
     * @returns {Observable} Un observable d'un booléen indiquant si la création du post s'est déroulé correctement
     */
    @log() createPost(body: any): Observable<any> {
        return this.http.post('/posts', body);
    }

    /**
     * @param {number} postId L'identifiant du post que l'on souhaite modifier
     * @param {any} body Un objet contenant 1 paramètre : Le contenu du post
     * Crée un post
     * @returns {Observable} Un observable d'un booléen indiquant si la création du post s'est déroulé correctement
     */
    @log() updatePost(postId: number, body: any): Observable<any> {
        return this.http.put('/posts/' + postId, body);
    }

    /**
     * @param { Number } postId L'id du post que l'on souhaite supprimer
     * Supprime un post
     * @returns {Observable} Un observable d'un booléen indiquant si la suppression du post s'est déroulé correctement
     */
    @log() deletePost(postId: number): Observable<any> {
        return this.http.delete('/posts/' + postId);
    }

    /**
     * @param { number } postId L'id du post auquel on souhaite ajouter une pièce jointe
     * @param { File } file La pièce-jointe que l'on souhaite ajouter
     * Ajoute une pièce-jointe à un post
     * @returns {Observable} Un observable d'un booléen indiquant si l'ajout de la pièce-jointe s'est déroulé correctement
     */
    @log() createAttachment(postId: number, file: File): Observable<any> {
        const body = new FormData();
        body.append('attachment', file);

        return this.http.post('/posts/' + postId + '/attachments', body);
    }

    /**
     * @param { number } postId L'id du post duquel on souhaite supprimer une pièce jointe
     * @param { string } attachmentName Le nom de la pièce-jointe que l'on souhaite supprimer
     * Supprime une pièce-jointe d'un post
     * @returns {Observable} Un observable d'un booléen indiquant si la suppression de la pièce-jointe s'est déroulé correctement
     */
    @log() deleteAttachment(postId: number, attachmentName: string): Observable<any> {
        return this.http.delete(
            '/posts/' + postId + '/attachments/' + encodeURIComponent(attachmentName)
        );
    }

    emitDeletedPost(postId: number) {
        this.deletedPost.next(postId);
    }

    emitUpdatedPost(post: Post) {
        this.updatedPost.next(post);
    }
}
