<div class="header">
    <span class="icon-easi-forum-line"></span>
    <span class="label">M'inscrire à un forum</span>
    <span class="icon-close" (click)="closeDialog()"></span>
</div>
<mat-dialog-content class="content">
    <div class="header-content">
        <span class="forums-count"
            ><strong>{{ forumsCount }}</strong> forums</span
        >
        <div class="structure-container" *ngIf="showStructuresDropdown()">
            <lib-uimm-dropdown
                [(items)]="structures"
                (itemsChange)="updateStructure($event)"
                [label]="'Structures'"
                [labelWidth]="'100%'"
                [maxWidth]="500"
                [maxHeight]="250"
                [design]="'boxes'"
                [headers]="{ search: true }"
            ></lib-uimm-dropdown>
        </div>
        <div class="search-container">
            <input
                class="input-container"
                type="text"
                placeholder="Chercher..."
                [(ngModel)]="searchTerm"
                (keyup.enter)="refreshData()"
            />
            <span class="icon-search" (click)="refreshData()"></span>
        </div>
    </div>
    <div class="top">
        <span class="name">Nom</span>
        <span class="author">Auteur</span>
        <span class="members">Inscrits</span>
        <span class="access">Accès</span>
    </div>
    <div
        class="forum-container"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        (scrolled)="nextPage()"
    >
        <app-subscribe-forum-entry
            *ngFor="let forum of forums"
            [forum]="forum"
            [selected]="isForumSelected(forum)"
            (select)="addToSubscribedForum($event)"
        ></app-subscribe-forum-entry>
    </div>
</mat-dialog-content>
<div class="footer">
    <div
        class="create-button"
        [ngClass]="{ disabled: !canSubscribeToForum() }"
        (click)="subscribeToForums()"
        *ngIf="!creationDone"
    >
        Valider
    </div>
    <div class="create-button" *ngIf="creationDone">
        <div class="loadingEntry">
            <div class="point1 point"></div>
            <div class="point2 point"></div>
            <div class="point3 point"></div>
        </div>
    </div>
</div>
