import { Injectable } from '@angular/core';

import { LoginService } from '../services/login.service';

import { Forum } from '../structures/forum';
import { ForumSubject } from '../structures/subject';
import { Post } from '../structures/post';
import { Theme } from '../structures/theme';

/**
 * Service gérant les permissions des utilisateurs à l'intérieur d'easi-forum
 */
@Injectable({
    providedIn: 'root'
})
export class PermissionService {
    constructor(private loginService: LoginService) {}

    /**
     * Indique si l'utilisateur possède les permissions de créer un forum
     * @returns { boolean } Vrai si l'utilisateur peut créer un forum, faux dans le cas contraire
     */
    canCreateForum(): boolean {
        return !(this.loginService.isLearner() || this.loginService.isTutor());
    }

    /**
     * @param { Forum } forum  Le forum que l'utilisateur souhaite modifier
     * @param { string } type Le type du forum : 'author', 'member' ou 'disabled'
     * Indique si l'utilisateur possède les permissions d'éditer un forum
     * @returns { boolean } Vrai si l'utilisateur peut éditer un forum, faux dans le cas contraire
     */
    canEditForum(forum: Forum, type: string): boolean {
        return (
            type === 'author' ||
            (type === 'member' && this.loginService.isNationalAdmin()) ||
            (type === 'member' &&
                this.loginService.isLocalAdmin() &&
                forum.owner.structureid === this.loginService.getUser().structureid) ||
            this.loginService.isForumModerator(forum.id)
        );
    }

    /**
     * @param { Forum } forum  Le forum que l'utilisateur souhaite modifier
     * @param { string } type Le type du forum : 'author', 'member' ou 'disabled'
     * Indique si l'utilisateur possède les permissions de désactiver un forum
     * @returns { boolean } Vrai si l'utilisateur peut désactiver un forum, faux dans le cas contraire
     */
    canDisableForum(forum: Forum, type: string): boolean {
        return (
            type === 'author' ||
            (type === 'member' && this.loginService.isNationalAdmin()) ||
            (type === 'member' &&
                this.loginService.isLocalAdmin() &&
                forum.owner.structureid === this.loginService.getUser().structureid)
        );
    }

    /**
     * Indique si l'utilisateur possède les permissions de masquer un forum
     * @returns { boolean } Vrai si l'utilisateur peut masquer un forum, faux dans le cas contraire
     */
    canMaskForum(type: string): boolean {
        if (this.loginService.getUser()) {
            return (
                (type === 'member' || type === 'history') &&
                !this.loginService.getUser().roles.learner &&
                !this.loginService.getUser().roles.prospect
            );
        }
        return false;
    }

    /**
     * @param { string } type Le type du forum : 'author', 'member' ou 'disabled'
     * Indique si l'utilisateur possède les permissions d'activer un forum
     * @returns { boolean } Vrai si l'utilisateur peut activer un forum, faux dans le cas contraire
     */
    canEnableForum(forum: Forum, type: string): boolean {
        if (this.loginService.getUser()) {
            return (
                type === 'history' &&
                forum.archived === true &&
                (forum.ownerid === this.loginService.getUser().id ||
                    this.loginService.isNationalAdmin() ||
                    (this.loginService.isLocalAdmin() &&
                        forum.owner.structureid === this.loginService.getUser().structureid))
            );
        }
        return false;
    }

    /**
     * @param { string } type Le type du forum : 'author', 'member' ou 'disabled'
     * Indique si l'utilisateur possède les permissions de supprimer un forum
     * @returns { boolean } Vrai si l'utilisateur peut supprimer un forum, faux dans le cas contraire
     */
    canDeleteForum(forum: Forum, type: string): boolean {
        if (this.loginService.getUser()) {
            return (
                type === 'history' &&
                forum.archived === true &&
                (forum.ownerid === this.loginService.getUser().id ||
                    this.loginService.isNationalAdmin() ||
                    (this.loginService.isLocalAdmin() &&
                        forum.owner.structureid === this.loginService.getUser().structureid))
            );
        }
        return false;
    }

    /**
     * Indique si l'utilisateur possède les permissions de créer un thème
     * @returns { boolean } Vrai si l'utilisateur peut créer un thème, faux dans le cas contraire
     */
    canCreateTheme(forum: Forum): boolean {
        if (this.loginService.getUser()) {
            if (this.loginService.isNationalAdmin()) {
                return true;
            }
            if (
                this.loginService.isLocalAdmin() &&
                forum.owner.structureid === this.loginService.getUser().structureid
            ) {
                return true;
            }
            if (forum.ownerid === this.loginService.getUser().id) {
                return true;
            }
            if (this.loginService.isForumModerator(forum.id)) {
                return true;
            }
            return false;
        }
        return false;
    }

    /**
     * Indique si l'utilisateur possède les permissions de déplacer un thème
     * @returns { boolean } Vrai si l'utilisateur peut déplacer un thème, faux dans le cas contraire
     */
    canMoveTheme(forum: Forum): boolean {
        if (this.loginService.getUser()) {
            if (this.loginService.isAdmin()) {
                return true;
            }
            if (forum.ownerid === this.loginService.getUser().id) {
                return true;
            }
            if (this.loginService.isForumModerator(forum.id)) {
                return true;
            }
            return false;
        }
        return false;
    }

    /**
     * Indique si l'utilisateur possède les permissions d'éditer un thème
     * @returns { boolean } Vrai si l'utilisateur peut éditer un thème, faux dans le cas contraire
     */
    canEditTheme(forum: Forum): boolean {
        if (this.loginService.getUser()) {
            if (this.loginService.isAdmin()) {
                return true;
            }
            if (forum.ownerid === this.loginService.getUser().id) {
                return true;
            }
            if (this.loginService.isForumModerator(forum.id)) {
                return true;
            }
            return false;
        }
        return false;
    }

    /**
     * Indique si l'utilisateur possède les permissions de supprimer un thème
     * @returns { boolean } Vrai si l'utilisateur peut supprimer un thème, faux dans le cas contraire
     */
    canDeleteTheme(forum: Forum): boolean {
        if (this.loginService.getUser()) {
            if (this.loginService.isAdmin()) {
                return true;
            }
            if (forum.ownerid === this.loginService.getUser().id) {
                return true;
            }
            if (this.loginService.isForumModerator(forum.id)) {
                return true;
            }
            return false;
        }
        return false;
    }

    /**
     * Indique si l'utilisateur possède les permissions de créer un sous-thème dans le thème
     * @returns { boolean } Vrai si l'utilisateur peut créer un sous-thème, faux dans le cas contraire
     */
    canCreateSubthemeInTheme(theme: Theme): boolean {
        if (this.loginService.getUser()) {
            if (this.loginService.isNationalAdmin()) {
                return true;
            }
            if (theme.forumOwner.id === this.loginService.getUser().id) {
                return true;
            }
            if (theme.authorid === this.loginService.getUser().id) {
                return true;
            }
            if (this.loginService.isForumModerator(theme.hierarchy.forum.id)) {
                return true;
            }
            return false;
        }
        return false;
    }

    /**
     * Indique si l'utilisateur possède les permissions de déplacer un sous-thème
     * @returns { boolean } Vrai si l'utilisateur peut déplacer un sous-thème, faux dans le cas contraire
     */
    canMoveSubtheme(forum: Forum): boolean {
        if (this.loginService.getUser()) {
            if (this.loginService.isAdmin()) {
                return true;
            }
            if (forum.ownerid === this.loginService.getUser().id) {
                return true;
            }
            if (
                forum.users.find((u) => u.id === this.loginService.getUser().id)?.role ===
                'moderator'
            ) {
                return true;
            }
            if (this.loginService.isForumModerator(forum.id)) {
                return true;
            }
            return false;
        }
        return false;
    }

    /**
     * Indique si l'utilisateur possède les permissions d'éditer un sous-thème
     * @returns { boolean } Vrai si l'utilisateur peut éditer un sous-thème, faux dans le cas contraire
     */
    canEditSubtheme(forum: Forum): boolean {
        if (this.loginService.getUser()) {
            if (this.loginService.isAdmin()) {
                return true;
            }
            if (forum.ownerid === this.loginService.getUser().id) {
                return true;
            }
            if (
                forum.users.find((u) => u.id === this.loginService.getUser().id)?.role ===
                'moderator'
            ) {
                return true;
            }
            if (this.loginService.isForumModerator(forum.id)) {
                return true;
            }
            return false;
        }
        return false;
    }

    /**
     * Indique si l'utilisateur possède les permissions de supprimer un sous-thème
     * @returns { boolean } Vrai si l'utilisateur peut supprimer un sous-thème, faux dans le cas contraire
     */
    canDeleteSubtheme(forum: Forum): boolean {
        if (this.loginService.getUser()) {
            if (this.loginService.isAdmin()) {
                return true;
            }
            if (forum.ownerid === this.loginService.getUser().id) {
                return true;
            }
            if (
                forum.users.find((u) => u.id === this.loginService.getUser().id)?.role ===
                'moderator'
            ) {
                return true;
            }
            if (this.loginService.isForumModerator(forum.id)) {
                return true;
            }
            return false;
        }
        return false;
    }

    /**
     * Indique si l'utilisateur possède les permissions de créer un sujet
     * @returns { boolean } Vrai si l'utilisateur peut créer un sujet, faux dans le cas contraire
     */
    canCreateSubjectInForum(forum: Forum): boolean {
        if (this.loginService.getUser()) {
            if (this.loginService.isNationalAdmin()) {
                return true;
            }
            if (
                this.loginService.isLocalAdmin() &&
                forum.owner.structureid === this.loginService.getUser().structureid
            ) {
                return true;
            }
            if (forum.ownerid === this.loginService.getUser().id) {
                return true;
            }
            if (this.loginService.isForumModerator(forum.id)) {
                return true;
            }
            for (const i in forum.users) {
                if (
                    forum.users[i].id === this.loginService.getUser().id &&
                    forum.users[i].rights >= 7
                ) {
                    return true;
                }
            }
            for (const i in forum.groups) {
                if (forum.groups[i].id) {
                    for (const j in this.loginService.getUser().groups) {
                        if (
                            forum.groups[i].id === this.loginService.getUser().groups[j].id &&
                            forum.groups[i].rights >= 7
                        ) {
                            return true;
                        }
                    }
                }
            }
            for (const i in forum.roles) {
                if (
                    this.loginService.getUser().roles[forum.roles[i].shortname] &&
                    forum.roles[i].rights >= 7 &&
                    forum.roles[i].structureid === this.loginService.getUser().structureid
                ) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Indique si l'utilisateur possède les permissions de créer un sujet
     * @returns { boolean } Vrai si l'utilisateur peut créer un sujet, faux dans le cas contraire
     */
    canCreateSubjectInTheme(forum: Forum): boolean {
        if (this.loginService.getUser()) {
            for (const i in forum.users) {
                if (
                    forum.users[i].id === this.loginService.getUser().id &&
                    forum.users[i].rights === 1
                ) {
                    return false;
                }
            }
            for (const i in forum.groups) {
                if (forum.groups[i].id) {
                    for (const j in this.loginService.getUser().groups) {
                        if (
                            forum.groups[i].id === this.loginService.getUser().groups[j].id &&
                            forum.groups[i].rights === 1
                        ) {
                            return false;
                        }
                    }
                }
            }
            for (const i in forum.roles) {
                if (
                    this.loginService.getUser().roles[forum.roles[i].shortname] &&
                    forum.roles[i].rights === 1 &&
                    forum.roles[i].structureid === this.loginService.getUser().structureid
                ) {
                    return false;
                }
            }
            return true;
        }
        return true;
    }

    /**
     * Indique si l'utilisateur possède les permissions d'épingler un sujet
     * @returns { boolean } Vrai si l'utilisateur peut épingler un sujet, faux dans le cas contraire
     */
    canPinSubject(forum: Forum): boolean {
        if (this.loginService.getUser()) {
            if (this.loginService.isAdmin()) {
                return true;
            }
            if (forum.ownerid === this.loginService.getUser().id) {
                return true;
            }
            if (this.loginService.isForumModerator(forum.id)) {
                return true;
            }
            return false;
        }
        return false;
    }

    /**
     * Indique si l'utilisateur possède les permissions de déplacer un sujet
     * @returns { boolean } Vrai si l'utilisateur peut déplacer un sujet, faux dans le cas contraire
     */
    canMoveSubject(forum: Forum, subject: ForumSubject): boolean {
        if (this.loginService.getUser()) {
            if (this.loginService.isAdmin()) {
                return true;
            }
            if (forum.ownerid === this.loginService.getUser().id) {
                return true;
            }
            if (subject.authorid === this.loginService.getUser().id) {
                return true;
            }
            if (this.loginService.isForumModerator(forum.id)) {
                return true;
            }
            return false;
        }
        return false;
    }

    /**
     * Indique si l'utilisateur possède les permissions de déplacer un sujet
     * @returns { boolean } Vrai si l'utilisateur peut déplacer un sujet, faux dans le cas contraire
     */
    canMoveSubjectInForum(forum: Forum, subject: ForumSubject): boolean {
        if (this.loginService.getUser()) {
            if (this.loginService.isAdmin()) {
                return true;
            }
            if (forum.ownerid === this.loginService.getUser().id) {
                return true;
            }
            if (this.loginService.isForumModerator(forum.id)) {
                return true;
            }
            return false;
        }
        return false;
    }

    /**
     * Indique si l'utilisateur possède les permissions d'éditer un sujet
     * @returns { boolean } Vrai si l'utilisateur peut éditer un sujet, faux dans le cas contraire
     */
    canEditSubject(forum: Forum, subject: ForumSubject): boolean {
        if (this.loginService.getUser()) {
            if (this.loginService.isAdmin()) {
                return true;
            }
            if (forum.ownerid === this.loginService.getUser().id) {
                return true;
            }
            if (subject.authorid === this.loginService.getUser().id) {
                return true;
            }
            if (this.loginService.isForumModerator(forum.id)) {
                return true;
            }
            return false;
        }
        return false;
    }

    /**
     * Indique si l'utilisateur possède les permissions de supprimer un sujet
     * @returns { boolean } Vrai si l'utilisateur peut supprimer un sujet, faux dans le cas contraire
     */
    canDeleteSubject(forum: Forum, subject: ForumSubject): boolean {
        if (this.loginService.getUser()) {
            if (this.loginService.isAdmin()) {
                return true;
            }
            if (forum.ownerid === this.loginService.getUser().id) {
                return true;
            }
            if (subject.authorid === this.loginService.getUser().id) {
                return true;
            }
            if (this.loginService.isForumModerator(forum.id)) {
                return true;
            }
            return false;
        }
        return false;
    }

    /**
     * Indique si l'utilisateur possède les permissions de créer un post
     * @returns { boolean } Vrai si l'utilisateur peut créer un post, faux dans le cas contraire
     */
    canCreatePost(forum: Forum): boolean {
        if (this.loginService.getUser()) {
            for (const i in forum.users) {
                if (
                    forum.users[i].id === this.loginService.getUser().id &&
                    forum.users[i].rights === 1
                ) {
                    return false;
                }
            }
            for (const i in forum.groups) {
                if (forum.groups[i].id) {
                    for (const j in this.loginService.getUser().groups) {
                        if (
                            forum.groups[i].id === this.loginService.getUser().groups[j].id &&
                            forum.groups[i].rights === 1
                        ) {
                            return false;
                        }
                    }
                }
            }
            for (const i in forum.roles) {
                if (
                    this.loginService.getUser().roles[forum.roles[i].shortname] &&
                    forum.roles[i].rights === 1 &&
                    forum.roles[i].structureid === this.loginService.getUser().structureid
                ) {
                    return false;
                }
            }
            return true;
        }
        return true;
    }

    /**
     * Indique si l'utilisateur possède les permissions d'éditer un post
     * @returns { boolean } Vrai si l'utilisateur peut éditer un post, faux dans le cas contraire
     */
    canEditPost(forum: Forum, post: Post): boolean {
        if (this.loginService.getUser()) {
            if (this.loginService.isAdmin()) {
                return true;
            }
            if (forum.ownerid === this.loginService.getUser().id) {
                return true;
            }
            if (post.authorid === this.loginService.getUser().id) {
                return true;
            }
            if (this.loginService.isForumModerator(forum.id)) {
                return true;
            }
            return false;
        }
        return false;
    }

    /**
     * Indique si l'utilisateur possède les permissions de supprimer un post
     * @returns { boolean } Vrai si l'utilisateur peut supprimer un post, faux dans le cas contraire
     */
    canDeletePost(forum: Forum, post: Post): boolean {
        if (this.loginService.getUser()) {
            if (this.loginService.isAdmin()) {
                return true;
            }
            if (forum.ownerid === this.loginService.getUser().id) {
                return true;
            }
            if (post.authorid === this.loginService.getUser().id) {
                return true;
            }
            if (this.loginService.isForumModerator(forum.id)) {
                return true;
            }
            return false;
        }
        return false;
    }
}
