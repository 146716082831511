import { Component, Output, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

/**
 * Composant gérant l'affichage de la fenêtre d'erreur
 */
@Component({
    selector: 'app-select-forum',
    templateUrl: './select-forum.component.html',
    styleUrls: ['./select-forum.component.scss']
})
export class SelectForumComponent {
    constructor(public dialogRef: MatDialogRef<SelectForumComponent>) {}

    @Output() openCreateForumDialog: EventEmitter<boolean> = new EventEmitter();

    selectForum(type: boolean) {
        this.closeDialog();
        this.openCreateForumDialog.emit(type);
    }

    /**
     * Ferme la fenêtre d'erreur
     */
    closeDialog() {
        this.dialogRef.close();
    }
}
