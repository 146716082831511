import {
    Component,
    OnInit,
    HostListener,
    Input,
    ElementRef,
    ViewChild,
    OnDestroy
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { DialogService } from '../../../../services/dialog.service';
import { ThemeService } from '../../../../services/theme.service';
import { PermissionService } from '../../../../services/permission.service';

import { Forum } from '../../../../structures/forum';
import { Theme } from '../../../../structures/theme';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-theme-entry',
    templateUrl: './theme-entry.component.html',
    styleUrls: ['./theme-entry.component.scss']
})
export class ThemeEntryComponent implements OnInit, OnDestroy {
    @Input() theme: Theme;
    @Input() forum: Forum;
    @ViewChild('inputTitle') inputTitle: ElementRef;

    initialTheme: string;
    editing: boolean;

    private subscriptions = new Subscription();

    constructor(
        private dialogService: DialogService,
        private permissionService: PermissionService,
        private themeService: ThemeService
    ) {}

    @HostListener('window:click', ['$event']) onMessage(event: any) {
        this.editing = false;
    }

    ngOnInit() {
        this.initialTheme = this.theme.title;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    /**
     * Indique l'icône à afficher selon le type/rôle de l'élement
     * @returns {string} La classe CSS à appliquer afin d'afficher l'icône correspondante
     */
    getRoleIcon(): string {
        if (this.theme.last.author.roles.learner) {
            return 'icon-apprenant';
        } else if (this.theme.author.roles.prospect) {
            return 'icon-apprenant-prospect';
        } else if (this.theme.last.author.roles.internalTeacher) {
            return 'icon-formateurinterne';
        } else if (this.theme.last.author.roles.externalTeacher) {
            return 'icon-formateurexterne';
        } else if (this.theme.last.author.roles.siteTeacher) {
            return 'icon-icon-formateur-site';
        } else if (this.theme.last.author.roles.corporationTeacher) {
            return 'icon-FormateurEntreprise';
        } else if (this.theme.last.author.roles.tuteurentreprise) {
            return 'icon-tuteurentreprise';
        } else if (this.theme.last.author.roles.localAdmin) {
            return 'icon-adminlocal';
        } else if (this.theme.last.author.roles.nationalAdmin) {
            return 'icon-adminnational';
        }
    }

    getPostsCount(): number {
        if (this.theme.postscount) {
            return this.theme.postscount;
        }
        return 0;
    }

    getThemeLastMessageAuthor(): string {
        if (this.theme.last) {
            return (
                this.theme.last.author.lastname.toUpperCase() +
                ' ' +
                this.theme.last.author.firstname
            );
        }
    }

    getThemeLastMessageDate(): Date {
        if (this.theme.last) {
            return new Date(this.theme.last.timemodified);
        }
    }

    isViewedTheme(): boolean {
        return this.theme.viewed;
    }

    showIcons(): Boolean {
        return this.canMoveTheme() || this.canEditTheme() || this.canDeleteTheme();
    }

    canMoveTheme(): boolean {
        return this.permissionService.canMoveTheme(this.forum);
    }

    canEditTheme(): boolean {
        return this.permissionService.canEditTheme(this.forum);
    }

    canDeleteTheme(): boolean {
        return this.permissionService.canDeleteTheme(this.forum);
    }

    moveUpTheme($event: Event) {
        $event.stopPropagation();
        if (this.canMoveUpTheme()) {
            this.subscriptions.add(
                this.themeService.moveUpTheme(this.theme.id).subscribe(
                    (data: any) => {
                        this.themeService.emitMovedUpTheme(this.theme);
                    },
                    (error: HttpErrorResponse) => {
                        this.dialogService.openErrorDialog(error.error.userMessage);
                    }
                )
            );
        }
    }

    canMoveUpTheme() {
        return this.forum.themes.indexOf(this.theme) > 0;
    }

    moveDownTheme($event: Event) {
        $event.stopPropagation();
        if (this.canMoveDownTheme()) {
            this.subscriptions.add(
                this.themeService.moveDownTheme(this.theme.id).subscribe(
                    (data: any) => {
                        this.themeService.emitMovedDownTheme(this.theme);
                    },
                    (error: HttpErrorResponse) => {
                        this.dialogService.openErrorDialog(error.error.userMessage);
                    }
                )
            );
        }
    }

    canMoveDownTheme() {
        return this.forum.themes.indexOf(this.theme) < this.forum.themes.length - 1;
    }

    editTheme($event: Event) {
        $event.stopPropagation();
        this.editing = true;
        setTimeout(() => {
            this.inputTitle.nativeElement.focus();
        });
    }

    deleteTheme($event: Event) {
        $event.stopPropagation();
        this.dialogService.openWarningDialog('DELETE_THEME', this.theme.id);
    }

    cancelEditTheme() {
        this.editing = false;
        this.theme.title = this.initialTheme;
    }

    renameTheme($event: Event) {
        $event.stopPropagation();
        if (this.theme.title.length > 0) {
            this.subscriptions.add(
                this.themeService.updateTheme(this.theme).subscribe(() => {
                    this.editing = false;
                    this.initialTheme = this.theme.title;
                })
            );
        } else {
            this.editing = false;
            this.theme.title = this.initialTheme;
        }
    }
}
