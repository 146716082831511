<div class="main" [ngClass]="{ hidden: reduced }">
    <div class="header">
        <div class="forums-count">
            <strong>{{ totalForumsCount }}</strong> forums
        </div>
        <div class="right-container">
            <div class="search-container">
                <div
                    class="search-input-container"
                    [ngClass]="{ active: activeSearch, empty: isSearchEmpty() }"
                >
                    <input
                        class="library-search-field"
                        placeholder="Chercher..."
                        [(ngModel)]="search"
                        (keyup.enter)="refreshDataFromSearch()"
                        #searchElement
                    />
                    <span class="icon-Fermerdetails" (mousedown)="resetSearch($event)"></span>
                    <span
                        class="icon-search"
                        [ngClass]="{ blue: !isSearchEmpty() }"
                        (click)="refreshDataFromSearch()"
                        *ngIf="activeSearch"
                    ></span>
                    <span
                        class="icon-search"
                        [ngClass]="{ blue: !isSearchEmpty() }"
                        (click)="toggleActiveSearch()"
                        *ngIf="!activeSearch"
                    ></span>
                </div>
            </div>
            <div
                class="icon-minimizebloc"
                [ngClass]="{ inversed: reduced }"
                (click)="toggleReduce()"
            ></div>
        </div>
    </div>
    <div class="separator"></div>
    <div class="container">
        <div class="started-forums" *ngIf="canCreateForum()">
            <div
                class="title"
                [class.enabled]="startedForumsCount > 0"
                (click)="startedForumsCount > 0 ? toggleAuthorForums() : null"
            >
                <span class="icon-partage-avec-autres"></span>
                <span class="label">Mes forums ({{ startedForumsCount }})</span>
                <span
                    class="icon-down"
                    *ngIf="startedForumsCount > 0"
                    [ngClass]="{ open: !isClosedAuthorForums }"
                ></span>
            </div>
            <div
                id="author-forums-container"
                [@slideToggle]="isClosedAuthorForums ? 'closed' : 'open'"
            >
                <app-nav-entry
                    routerLink="/forum/{{ forum.id }}"
                    *ngFor="let forum of authorsForums"
                    [forum]="forum"
                    (click)="selectForum(forum.id)"
                    [selectedForum]="selectedForum"
                    [type]="'author'"
                ></app-nav-entry>
            </div>
        </div>
        <div class="separator second" *ngIf="canCreateForum()"></div>
        <div class="member-forums">
            <div
                class="title"
                [class.enabled]="memberForumsCount > 0"
                (click)="memberForumsCount > 0 ? toggleMemberForums() : null"
            >
                <span class="icon-partage-avec-moi"></span>
                <span class="label">Autres forums ({{ memberForumsCount }})</span>
                <span
                    class="icon-down"
                    *ngIf="memberForumsCount > 0"
                    [ngClass]="{ open: !isClosedMemberForums }"
                ></span>
            </div>
            <div
                id="member-forums-container"
                [@slideToggle]="isClosedMemberForums ? 'closed' : 'open'"
            >
                <app-nav-entry
                    routerLink="/forum/{{ forum.id }}"
                    *ngFor="let forum of membersForums"
                    [forum]="forum"
                    (click)="selectForum(forum.id)"
                    [selectedForum]="selectedForum"
                    [type]="'member'"
                ></app-nav-entry>
            </div>
        </div>
        <div class="separator second"></div>
        <div class="disabled-forums">
            <div
                class="title"
                [class.enabled]="disabledForumsCount > 0"
                (click)="disabledForumsCount > 0 ? toggleDisabledForums() : null"
            >
                <span class="icon-easi-forum-line"></span>
                <span class="label">Mes forums désactivés ({{ disabledForumsCount }})</span>
                <span
                    class="icon-down"
                    *ngIf="disabledForumsCount > 0"
                    [ngClass]="{ open: !isClosedDisabledForums }"
                ></span>
            </div>
            <div
                id="disabled-forums-container"
                [@slideToggle]="isClosedDisabledForums ? 'closed' : 'open'"
            >
                <app-nav-entry
                    routerLink="/forum/{{ forum.id }}"
                    *ngFor="let forum of disabledForums"
                    [forum]="forum"
                    (click)="selectForum(forum.id)"
                    [selectedForum]="selectedForum"
                    [type]="'history'"
                ></app-nav-entry>
            </div>
        </div>
    </div>
    <div class="separator"></div>
    <div class="subscribe-forum">
        <div class="button-subscribe" (click)="openSubscribeForumDialog()">Trouver un forum</div>
    </div>
    <div
        class="add-forum"
        *ngIf="canCreateForum()"
        (click)="openSelectForumDialog()"
        tooltip="Créer un nouveau forum"
        tooltipPosition="left"
        tooltipColor="#955099"
    >
        <span class="icon-plus"></span>
    </div>
</div>
