import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { ForumService } from '../../../services/forum.service';
import { DialogService } from '../../../services/dialog.service';
import { UsersService } from '../../../services/users.service';

@Component({
    selector: 'app-shared-forum-entry',
    templateUrl: './shared-forum-entry.component.html',
    styleUrls: ['./shared-forum-entry.component.scss']
})
export class SharedForumEntryComponent implements OnInit, OnDestroy {
    @Input() forum: any;
    @Input() selected: boolean;

    openedForum: boolean;

    // getForum$: Subscription;
    // getGroup$: Subscription;
    private subscriptions = new Subscription();

    constructor(
        private forumService: ForumService,
        private dialogService: DialogService,
        private userService: UsersService
    ) {}

    /**
     * @param {any} user Un membre du forum actuel
     * Indique l'icône à afficher selon le type et le rôle de l'utilisateur
     * @returns {string} La classe CSS correspondante à l'icône à afficher
     */
    getUserIcon(user: any): string {
        if (user.roles.learner) {
            return 'icon-apprenant';
        } else if (user.roles.prospect) {
            return 'icon-apprenant-prospect';
        } else if (user.roles.internalTeacher) {
            return 'icon-formateurinterne';
        } else if (user.roles.externalTeacher) {
            return 'icon-formateurexterne';
        } else if (user.roles.siteTeacher) {
            return 'icon-formateur-site';
        } else if (user.roles.corporationTeacher) {
            return 'icon-FormateurEntreprise';
        } else if (user.roles.tuteurentreprise) {
            return 'icon-tuteurentreprise';
        } else if (user.roles.localAdmin) {
            return 'icon-adminlocal';
        } else if (user.roles.nationalAdmin) {
            return 'icon-adminnational';
        }
    }

    /**
     * Indique le nom de l'auteur du forum
     * @returns {string} Le prénom et le nom de l'auteur du forum
     */
    getAuthorName(): string {
        return this.forum.owner.lastname.toUpperCase() + ' ' + this.forum.owner.firstname;
    }

    /**
     * Indique si le forum actuel est sélectionné
     * @returns {Boolean} True si le forum actuel est sélectionné, faux dans le cas contraire
     */
    isForumSelected(): boolean {
        return this.selected;
    }

    /**
     * Indique si le forum actuel est ouvert
     * @returns {Boolean} True si le forum actuel est ouvert, faux dans le cas contraire
     */
    isForumOpened(): boolean {
        return this.openedForum;
    }

    /**
     * Ouvre et affiche le forum actuel
     */
    toggleForum(event: MouseEvent) {
        event.stopImmediatePropagation();
        if (this.forum.users === undefined) {
            this.subscriptions.add(
                this.forumService.getForum(this.forum.id).subscribe(
                    (data: any) => {
                        for (const i in data.users) {
                            if (data.users[i].id === this.forum.owner.id) {
                                data.users.splice(i, 1);
                            }
                        }

                        this.forum.users = data.users;
                        this.forum.groups = data.groups;
                        this.openedForum = !this.openedForum;

                        for (const i in data.groups) {
                            if (data.groups[i].users === undefined) {
                                this.subscriptions.add(
                                    this.userService.getGroup(data.groups[i].id).subscribe(
                                        (data_group: any) => {
                                            data.groups[i].users = data_group.users;
                                        },
                                        (error: HttpErrorResponse) => {
                                            this.dialogService.openErrorDialog(
                                                error.error.userMessage
                                            );
                                        }
                                    )
                                );
                            }
                        }
                    },
                    (error: HttpErrorResponse) => {
                        this.dialogService.openErrorDialog(error.error.userMessage);
                    }
                )
            );
        } else {
            this.openedForum = !this.openedForum;
        }
    }

    ngOnInit() {
        this.openedForum = false;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
