<div class="header">
    <span class="icon-easi-forum-line"></span>
    <span class="label">{{ headerLabel }}</span>
    <span class="icon-close" (click)="closeDialog()"></span>
</div>
<mat-dialog-content class="content">
    <mat-label class="label">Sous-thème</mat-label>
    <input
        matInput
        class="value"
        type="text"
        placeholder="Nom du sous-theme"
        [(ngModel)]="subtheme.title"
        (keyup.enter)="createSubtheme()"
    />
</mat-dialog-content>
<div class="footer">
    <div
        class="create-button"
        [ngClass]="{ disabled: !canCreateSubtheme() }"
        (click)="createSubtheme()"
        *ngIf="!creating"
    >
        {{ footerLabel }}
    </div>
    <div class="create-button" *ngIf="creating">
        <div class="loadingEntry">
            <div class="point1 point"></div>
            <div class="point2 point"></div>
            <div class="point3 point"></div>
        </div>
    </div>
</div>
