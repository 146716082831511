<div class="header">
    <span class="icon-easi-forum-line"></span>
    <span class="label">{{ forum?.name }}</span>
    <span class="icon-close" (click)="closeDialog()"></span>
</div>
<mat-dialog-content class="content">
    <div class="description" *ngIf="forum?.description?.length">
        <div class="label">Description</div>
        <div class="value">{{ forum?.description }}</div>
    </div>
    <div class="separator" *ngIf="forum?.description.length"></div>
    <div class="members">
        <div class="top">
            <div class="label">Utilisateurs inscrits</div>
            <div class="users-count">
                <b>{{ groupNumber }}</b> groupe(s), <b>{{ learnerNumber }}</b> apprenant(s),
                <b>{{ teacherNumber }}</b> formateur(s) et <b>{{ roleNumber }}</b> role(s)
            </div>
        </div>
        <div class="members-container">
            <app-members-entry
                *ngFor="let user of forum?.users"
                [element]="user"
                [type]="'user'"
            ></app-members-entry>
            <app-members-entry
                *ngFor="let group of forum?.groups"
                [element]="group"
                [type]="'group'"
            ></app-members-entry>
            <app-members-entry
                *ngFor="let role of forum?.roles"
                [element]="role"
                [type]="'role'"
            ></app-members-entry>
        </div>
    </div>
</mat-dialog-content>
