import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { Subtheme } from '../structures/subtheme';
import { log } from './decorators/log.decorator';

/**
 * Service gérant tous les appels aux webservice liés aux sous-thèmes
 */
@Injectable({
    providedIn: 'root'
})
export class SubthemeService {
    constructor(private http: HttpClient) {}

    refreshAllSubthemes: Subject<string> = new Subject();
    refreshCurrentSubtheme: Subject<string> = new Subject();
    movedUpSubtheme: Subject<Subtheme> = new Subject();
    movedDownSubtheme: Subject<Subtheme> = new Subject();
    createdSubtheme: Subject<Subtheme> = new Subject();
    deletedSubtheme: Subject<number> = new Subject();

    /**
     * @param {any} subtheme Un objet contenant 2 paramètres : L'id du thème dans lequel on souhaite créer le sous-theme, et le nom du sous-theme
     * Crée un sous-theme
     * @returns {Observable} Un observable d'un booléen indiquant si la création du sous-theme s'est déroulé correctement
     */
    @log() createSubtheme(body: any): Observable<any> {
        return this.http.post('/subthemes', body);
    }

    /**
     * @param {any} subtheme Un objet contenant 2 paramètres : L'id du forum dans lequel on souhaite modifier le sous-theme, et le nom du sous-theme
     * Modifie un sous-theme
     * @returns {Observable} Un observable d'un booléen indiquant si la modification du sous-theme s'est déroulé correctement
     */
    @log() updateSubtheme(body: any): Observable<any> {
        return this.http.put('/subthemes/' + body.id, body);
    }

    /**
     * @param {number} id L'identifiant du sous-theme à récuperer
     * @param {string} searchTerm Une chaîne de caractère optionnel permettant de filtrer les sujets contenu dans le sous-thème
     * Récupère les informations d'un sous-theme spécifique
     * @returns {Observable} Un observable du sous-theme récupéré
     */
    @log() getSubtheme(id: number, searchTerm?: string): Observable<any> {
        const params: any = {};

        if (searchTerm) {
            params.search = searchTerm;
        }

        return this.http.get('/subthemes/' + id, { params });
    }

    /**
     * @param {number} id L'identifiant du sous-theme à supprimer
     * Supprime un sous-thème
     * @returns {Observable} Un observable d'un booléen indiquant si la suppression du sous-theme s'est déroulé correctement
     */
    @log() deleteSubtheme(id: number): Observable<any> {
        return this.http.delete('/subthemes/' + id);
    }

    /**
     * @param {number} id L'identifiant du sous-theme à remonter
     * Remonte un sous-theme  d'une position
     * @returns {Observable} Un observable du sous-theme déplacé
     */
    @log() moveUpSubtheme(id: number): Observable<any> {
        return this.http.put('/subthemes/' + id + '/up', {});
    }

    /**
     * @param {number} id L'identifiant du sous-theme à remonter
     * Remonte un sous-theme  d'une position
     * @returns {Observable} Un observable du sous-theme déplacé
     */
    @log() moveDownSubtheme(id: number): Observable<any> {
        return this.http.put('/subthemes/' + id + '/down', {});
    }

    refreshSubtheme(searchTerm?: string) {
        this.refreshCurrentSubtheme.next(searchTerm);
    }

    emitMovedUpSubtheme(subtheme: Subtheme) {
        this.movedUpSubtheme.next(subtheme);
    }

    emitMovedDownSubtheme(subtheme: Subtheme) {
        this.movedDownSubtheme.next(subtheme);
    }

    emitCreatedSubtheme(subtheme: Subtheme) {
        this.createdSubtheme.next(subtheme);
    }

    emitDeletedSubtheme(subthemeId: number) {
        this.deletedSubtheme.next(subthemeId);
    }
}
