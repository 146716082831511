<div class="sub-header">
    <span class="icon-easi-forum-line"></span>
    <div class="title">
        <span class="forum-name" routerLink="/forum/{{ forum?.id }}">{{ forum?.name }}</span>
        <span class="icon-deroulant" *ngIf="theme"></span>
        <div class="icon-theme" *ngIf="theme"></div>
        <span class="theme-name" *ngIf="theme" routerLink="/theme/{{ theme?.id }}">{{
            theme?.title
        }}</span>
        <span class="icon-deroulant" *ngIf="subtheme"></span>
        <div class="icon-Repertoire-plein" *ngIf="subtheme"></div>
        <span class="subtheme-name" *ngIf="subtheme" routerLink="/subtheme/{{ subtheme?.id }}">{{
            subtheme?.title
        }}</span>
        <span class="icon-deroulant" *ngIf="subject"></span>
        <span class="icon-Messages-cercle" *ngIf="subject"></span>
        <span class="subject-name" *ngIf="subject">{{ subject?.title }}</span>
    </div>
    <div
        class="icon-Nouvelleversioncontenu"
        (click)="refreshChildrenInterval()"
        [ngClass]="{ disabled: disabled }"
        [tooltip]="'Rafraîchir'"
        tooltipPosition="left"
        tooltipTextColor="#955099"
        tooltipColor="#fff"
    ></div>
    <div class="search-field">
        <input
            class="input-container"
            type="text"
            placeholder="Chercher..."
            [(ngModel)]="searchTerm"
            (keyup.enter)="refreshChildren()"
        />
        <span class="icon-search" (click)="refreshChildren()"></span>
    </div>
</div>
<router-outlet (activate)="onActivate($event)"></router-outlet>
