import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { DialogService } from '../../../services/dialog.service';
import { PermissionService } from '../../../services/permission.service';
import { SubthemeService } from '../../../services/subtheme.service';
import { SubjectService } from '../../../services/subject.service';

import { Subtheme } from '../../../structures/subtheme';
import { ForumSubject } from '../../../structures/subject';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-subtheme',
    templateUrl: './subtheme.component.html',
    styleUrls: ['./subtheme.component.scss']
})
export class SubthemeComponent implements OnInit, OnDestroy {
    routeParam$: Subscription;
    private subscriptions = new Subscription();

    subtheme: Subtheme;
    subthemeReady: boolean;

    creatorSelectorOpened: boolean;

    updateSubtheme: Subject<Subtheme> = new Subject();

    constructor(
        private dialogService: DialogService,
        private permissionService: PermissionService,
        private subthemeService: SubthemeService,
        private subjectService: SubjectService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit() {
        this.refreshSubtheme('');

        this.subscriptions.add(
            this.subthemeService.refreshCurrentSubtheme.subscribe((searchTerm: string) => {
                if (this.router.url === '/subtheme/' + this.subtheme.id) {
                    this.refreshSubtheme(searchTerm);
                }
            })
        );

        this.subscriptions.add(
            this.subjectService.createdSubjectInSubtheme.subscribe((subject: ForumSubject) => {
                subject.views = 0;
                this.subtheme.subjects.push(subject);
            })
        );

        this.subscriptions.add(
            this.subjectService.deletedSubject.subscribe((subjectId: number) => {
                let index: any;
                for (const i in this.subtheme.subjects) {
                    if (this.subtheme.subjects[i].id === subjectId) {
                        index = i;
                    }
                }
                this.subtheme.subjects.splice(index, 1);
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    /**
     * @param {any} data Un objet représentant le subtheme
     * Initialise le subtheme
     */
    refreshSubtheme(searchTerm: string) {
        if (this.routeParam$) {
            this.routeParam$.unsubscribe();
        }
        this.subthemeReady = false;
        this.routeParam$ = this.route.paramMap
            .pipe(
                switchMap((params: ParamMap) =>
                    this.subthemeService.getSubtheme(parseInt(params.get('id'), 10), searchTerm)
                )
            )
            .subscribe((data: any) => {
                this.subtheme = data;
                this.subthemeReady = true;
                this.updateSubtheme.next(this.subtheme);
            });
        this.subscriptions.add(this.routeParam$);
    }

    isSubthemeReady(): boolean {
        return this.subthemeReady;
    }

    getPinnedSubjects(): Array<ForumSubject> {
        return this.subtheme.subjects.filter(function (subject) {
            return subject.pinned;
        });
    }

    getPinnedSubjectsCount(): number {
        return this.subtheme.subjects.filter(function (subject) {
            return subject.pinned;
        }).length;
    }

    getRegularSubjects(): Array<ForumSubject> {
        return this.subtheme.subjects.filter(function (subject) {
            return !subject.pinned;
        });
    }

    getRegularSubjectsCount(): number {
        return this.subtheme.subjects.filter(function (subject) {
            return !subject.pinned;
        }).length;
    }

    canCreateSubject(): Boolean {
        return this.permissionService.canCreateSubjectInTheme(this.subtheme.hierarchy.forum);
    }

    openDialogCreateSubject() {
        this.creatorSelectorOpened = false;
        this.dialogService.openCreateSubjectDialog(this.subtheme.id, 'subtheme');
    }

    openCreatorSelector(): void {
        this.creatorSelectorOpened = !this.creatorSelectorOpened;
    }

    closeCreatorSelector(): void {
        this.creatorSelectorOpened = false;
    }

    isCreatorSelectorOpened(): boolean {
        return this.creatorSelectorOpened;
    }
}
