import { Component, Inject, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ForumService } from '../../services/forum.service';
import { SubjectService } from '../../services/subject.service';
import { PermissionService } from '../../services/permission.service';

import { Forum } from '../../structures/forum';
import { ForumSubject } from '../../structures/subject';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-move-subject',
    templateUrl: './move-subject.component.html',
    styleUrls: ['./move-subject.component.scss']
})
export class MoveSubjectComponent implements OnInit, OnDestroy {
    forum: Forum;
    subject: ForumSubject;
    moving: boolean;
    newPosition: any;
    initialPosition: any;

    headerLabel: string;
    footerLabel: string;

    private subscriptions = new Subscription();

    @Output() openErrorDialog: EventEmitter<string> = new EventEmitter();

    constructor(
        public dialogRef: MatDialogRef<MoveSubjectComponent>,
        private forumService: ForumService,
        private subjectService: SubjectService,
        private permissionService: PermissionService,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {}

    ngOnInit() {
        this.headerLabel = 'Déplacer le sujet';
        this.footerLabel = 'Valider';
        this.moving = false;
        this.subject = this.data.subject;
        this.initInitialPosition();
        this.subscriptions.add(
            this.forumService.getForum(this.data.forumId, undefined, true).subscribe(
                (data: Forum) => {
                    if (data.themes) {
                        for (const theme of data.themes) {
                            if (data.subthemes) {
                                for (const subtheme of data.subthemes) {
                                    if (subtheme.themeid === theme.id) {
                                        theme.subthemes.push(subtheme);
                                    }
                                }
                            }
                        }
                    }
                    this.forum = data;
                },
                (error: HttpErrorResponse) => {
                    this.openErrorDialog.emit(error.error.userMessage);
                }
            )
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    initInitialPosition() {
        this.initialPosition = {};
        this.initialPosition.instanceid = this.subject.instanceid;
        this.initialPosition.type = this.subject.type;
        this.newPosition = {};
        this.newPosition.instanceid = this.subject.instanceid;
        this.newPosition.type = this.subject.type;
    }

    isInitialPosition(instanceid: number, type: string): boolean {
        return this.initialPosition.instanceid === instanceid && this.initialPosition.type === type;
    }

    isNewPosition(instanceid: number, type: string): boolean {
        return this.newPosition.instanceid === instanceid && this.newPosition.type === type;
    }

    selectNewPosition(instanceid: number, type: string) {
        if (type === 'forum' && !this.canMoveSubjectInForum()) {
            return;
        }
        if (this.newPosition.instanceid === instanceid && this.newPosition.type === type) {
            this.newPosition = {};
        } else {
            this.newPosition.instanceid = instanceid;
            this.newPosition.type = type;
        }
    }

    moveSubject() {
        if (this.canMoveSubject()) {
            this.subject.instanceid = this.newPosition.instanceid;
            this.subject.type = this.newPosition.type;
            this.subscriptions.add(
                this.subjectService.updateSubject(this.subject).subscribe(
                    () => {
                        this.subjectService.emitDeletedSubject(this.subject.id);
                        this.closeDialog();
                    },
                    (error: HttpErrorResponse) => {
                        this.openErrorDialog.emit(error.error.userMessage);
                    }
                )
            );
        }
    }

    canMoveSubject(): boolean {
        return (
            this.newPosition.instanceid !== undefined &&
            this.newPosition.type !== undefined &&
            (this.newPosition.instanceid !== this.initialPosition.instanceid ||
                this.newPosition.type !== this.initialPosition.type)
        );
    }

    canMoveSubjectInForum(): boolean {
        return this.permissionService.canMoveSubjectInForum(this.forum, this.subject);
    }

    /**
     * Ferme la fenêtre d'enregistrement à un forum
     */
    closeDialog() {
        this.dialogRef.close();
    }
}
