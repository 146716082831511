import { Pipe, PipeTransform } from '@angular/core';
import { Structure } from '../structures/structure';

@Pipe({
    name: 'search'
})
export class SearchPipe implements PipeTransform {
    /**
     * @param { Array<Structure> } structures Un tableau contenant l'ensemble des structures sur lequel on souhaite filtrer
     * @param { string } filter Le filtre que l'on souhaite appliquer sur les structures
     * Un pipe permettant de filtrer les structures sur leur nom
     */
    transform(structures: Array<Structure>, filter: string): Array<Structure> {
        const res: Array<Structure> = [];

        if (!filter) {
            return structures;
        }

        for (const i in structures) {
            if (structures[i].name.toLowerCase().indexOf(filter.toLowerCase()) > -1) {
                res.push(structures[i]);
            }
        }
        return res;
    }
}
