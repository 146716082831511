import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, fromEvent, Subject, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { ConfigService } from './config.service';

import { CookieService } from 'ngx-cookie-service';
import { log } from './decorators/log.decorator';

/**
 * Service gérant l'utilisateur actuel
 */
@Injectable({
    providedIn: 'root'
})
export class LoginService {
    /**
     * L'utilisateur actuel
     */
    user: any;
    moderator: Array<number> = [];
    lastStructure: any = {};
    closeAllDialogs$: Subject<any> = new Subject();

    updateStructure: Subject<any> = new Subject();
    userReady: Subject<void> = new Subject();

    getCurrentUserForum$: Subscription;

    constructor(
        private http: HttpClient,
        private configService: ConfigService,
        private cookieService: CookieService
    ) {}

    /**
     * Renvoie l'utilisateur actuel
     * @returns {any} Un objet représentant l'utilisateur actuel tel que renvoyé par getCurrentUser
     */
    getUser(): any {
        if (this.user) {
            return this.user;
        }
    }

    /**
     * @returns {any} la derniere structure selectionnee par l'utilisateur.
     */
    getLastStructure(): any {
        return this.lastStructure;
    }

    /**
     * @description modifie l'id de la nom de la derniere structure qui avait ete selectionee par l'utilisateur.
     * @param {any} structure recue via un cookie.
     * @returns {void}
     */
    setLastStructure(structure: any): void {
        if (structure.structureid) {
            this.lastStructure.id = structure.structureid;
            this.lastStructure.name = structure.structurename;
        } else if (structure.id) {
            this.lastStructure.id = structure.id;
            this.lastStructure.name = structure.name;
        }
        this.updateStructure.next(this.lastStructure);
    }

    /**
     * @description update la structure et poste le message a easi-shared.
     * @returns {void}
     */
    updateLastStructure(structure: any): void {
        const iframe: any = document.getElementById('header-container');

        this.setLastStructure(structure);
        iframe.contentWindow.postMessage(
            { setCookies: { structureid: structure.id, structurename: structure.name } },
            '*'
        );
    }

    /**
     * Indique si l'utilisateur actuel est un apprenant
     * @returns {boolean} True si l'utilisateur actuel est un apprenant, faux dans le cas contraire
     */
    isLearner(): boolean {
        if (this.user) {
            return this.user.roles.learner || this.user.roles.prospect;
        }
    }

    /**
     * Indique si l'utilisateur actuel est un apprenant
     * @returns {boolean} True si l'utilisateur actuel est un apprenant, faux dans le cas contraire
     */
    isTutor(): boolean {
        if (this.user) {
            return this.user.roles.tutor;
        }
    }

    /**
     * Indique si l'utilisateur actuel est un administrateur
     * @returns {boolean} True si l'utilisateur actuel est un administrateur, faux dans le cas contraire
     */
    isAdmin(): boolean {
        if (this.user) {
            return this.user.roles.nationalAdmin || this.user.roles.localAdmin;
        }
    }

    /**
     * Indique si l'utilisateur actuel est un administrateur national
     * @returns {boolean} True si l'utilisateur actuel est un administrateur national, faux dans le cas contraire
     */
    isNationalAdmin(): boolean {
        if (this.user) {
            return this.user.roles.nationalAdmin;
        }
    }

    /**
     * Indique si l'utilisateur actuel est un administrateur local
     * @returns {boolean} True si l'utilisateur actuel est un administrateur local, faux dans le cas contraire
     */
    isLocalAdmin(): boolean {
        if (this.user) {
            return this.user.roles.localAdmin;
        }
    }

    /**
     * @param { number } forumId L'id d'un forum
     * Indique si l'utilisateur actuel est modérateur d'un forum
     * @returns { boolean } True si l'utilisateur actuel est modérateur du forum passé en paramètre, faux dans le cas contraire
     */
    isForumModerator(forumId: number): boolean {
        return this.moderator.indexOf(forumId) > -1;
    }

    /**
     * Renvoie l'utilisateur actuellement connecté sur la plateforme
     * @returns {Observable} Un observable de l'utilisateur actuel
     */
    @log() getCurrent(): Observable<any> {
        const iframe: any = document.getElementById('header-container');
        iframe.contentWindow.postMessage('getCurrent', '*');

        return fromEvent(window, 'message').pipe(
            filter((data: any) => {
                return data.data.current !== undefined;
            }),
            tap({
                error: (error) => {
                    this.isAuthenticated(error);
                }
            })
        );
    }

    /**
     * Renvoie l'utilisateur actuellement connecté sur la plateforme
     * @returns {Observable} Un observable de l'utilisateur actuel
     */
    @log() getCurrentUserForum(): Observable<any> {
        return this.http.get('/user/current').pipe(
            tap(
                (data) => {
                    if (this.cookieService.check('redirect-url')) {
                        const redirectUrl = this.cookieService.get('redirect-url');
                        this.cookieService.delete('redirect-url', '/');
                        window.location.href = redirectUrl;
                        window.location.reload();
                    }
                    this.user = data;
                    this.getModerators().subscribe((moderatorArray: Array<number>) => {
                        this.moderator = moderatorArray;
                    });
                    this.userReady.next();
                },
                (error) => {
                    const expires = new Date();
                    expires.setFullYear(expires.getFullYear() + 1);
                    this.cookieService.set(
                        'redirect-url',
                        window.location.href,
                        expires,
                        '/',
                        '',
                        true,
                        'None'
                    );
                }
            )
        );
    }

    /**
     * Renvoie l'utilisateur actuellement connecté sur la plateforme
     * @returns {Observable} Un observable de l'utilisateur actuel
     */
    @log() getModerators(): Observable<any> {
        return this.http.get('/forums/moderator');
    }

    @log() postCookies() {
        return this.http.post('/setTestCookie', undefined);
    }

    @log() getCookies() {
        return this.http.get('/getTestCookie');
    }

    /**
     * @param { HttpErrorResponse } error L'erreur renvoyé par les web-services
     * Redirige l'utilisateur sur easi-connect s'il n'est pas connecté
     */
    isAuthenticated(error: HttpErrorResponse) {
        if (error.error.errorCode && error.error.errorCode === 'USER_NOT_AUTH') {
            window.location.href = this.configService.getRedirectEndPoint();
        }
    }

    initCheckConnectedInterval() {
        setInterval(() => {
            this.http.get<boolean>(`/is_still_connected.php`).subscribe((data: boolean) => {
                if (data === false) {
                    const iframe: HTMLIFrameElement = document.getElementById(
                        'header-container'
                    ) as HTMLIFrameElement;
                    iframe.contentWindow.postMessage('userDisconnected', '*');
                    this.closeAllDialogs$.next(true);
                }
            });
        }, 5 * 60 * 1000);
    }
}
