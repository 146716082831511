<div [ngClass]="{ hidden: isLoading() }">
    <app-header class="easi-header"></app-header>
    <app-flash-message></app-flash-message>
    <app-nav
        class="left-panel"
        [ngClass]="{ reduced: !isPanelOpen }"
        (select)="updateCurrentForum($event)"
        (resize)="toggleNav()"
    ></app-nav>
    <app-main
        class="main-panel"
        (forumSelected)="updateCurrentForum($event)"
        [ngClass]="{ hidden: showCarousel(), enlarged: !isPanelOpen }"
    ></app-main>
    <app-carousel
        class="carousel"
        *ngIf="showCarousel()"
        [ngClass]="{ enlarged: !isPanelOpen }"
    ></app-carousel>
    <app-footer></app-footer>
</div>
<app-loading *ngIf="isLoading()"></app-loading>
