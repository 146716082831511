<div class="post" [ngClass]="{ 'quote-post': isQuote }">
    <div class="header" [ngClass]="{ deleted: isDeleted() }">
        <div class="icon" [ngClass]="getIconStyle()"></div>
        <div class="author-name">
            {{ post.author.lastname.toUpperCase() }} {{ post.author.firstname }}
        </div>
        <div class="post-time">
            le {{ post.timecreated | date: 'dd/MM/yyyy' }} à {{ post.timecreated | date: 'HH:mm' }}
        </div>
        <div class="icons" *ngIf="!isDeleted() && !isQuote">
            <span
                class="icon-bin"
                (click)="openDeletePostDialog()"
                *ngIf="canDeletePost()"
                [tooltip]="'Supprimer le message'"
                tooltipPosition="left"
                tooltipColor="#955099"
            ></span>
            <span
                class="icon-Editer"
                (click)="setEdition()"
                *ngIf="canEditPost()"
                [tooltip]="'Modifier le message'"
                tooltipPosition="left"
                tooltipColor="#955099"
            ></span>
            <span
                class="icon-assigner"
                (click)="quotePost()"
                [tooltip]="'Répondre en citant'"
                tooltipPosition="left"
                tooltipColor="#955099"
                *ngIf="canWrite()"
            ></span>
        </div>
        <div class="icons" *ngIf="!isDeleted() && isQuote && !published">
            <span class="icon-close" (click)="quotePost()"></span>
        </div>
        <div class="message-deleted">
            <div class="deleted-owner" *ngIf="isDeleted() && post.deleterid === post.authorid">
                Ce message a été supprimé par son auteur
            </div>
            <div class="deleted-moderator" *ngIf="isDeleted() && post.deleterid !== post.authorid">
                Ce message a été supprimé par un modérateur
            </div>
        </div>
    </div>
    <div class="quote" *ngIf="!isQuote && post.quote">
        <app-post
            *ngIf="post.quote !== 'deleted'"
            [post]="post.quote"
            [forum]="forum"
            [isQuote]="true"
            [published]="true"
        ></app-post>
        <span *ngIf="post.quote === 'deleted'">Post supprimé</span>
    </div>
    <div class="message-container" *ngIf="!isDeleted()">
        <div class="message" [innerHtml]="formattedMessage" *ngIf="!isEditing()"></div>
        <div class="input-container" *ngIf="isEditing()">
            <div
                class="input"
                id="test"
                #input
                contenteditable
                [innerHtml]="formattedMessage"
                (keyup.enter)="updatePost()"
                (keyup.escape)="stopEditing()"
            ></div>
            <span class="icon-Envoyer" (click)="updatePost()"></span>
        </div>
        <div class="upload-container" (click)="openUploadFilesDialog()" *ngIf="isEditing()">
            <div class="icon-Importer"></div>
        </div>
        <div class="attachments" *ngIf="post.attachments && post.attachments.length > 0">
            <div class="attachment-container" *ngFor="let attachment of post.attachments">
                <img
                    class="pic-preview"
                    alt="preview"
                    *ngIf="isImage(attachment)"
                    (click)="openPreview(attachment)"
                    [src]="getAttachmentUrl(attachment)"
                />
                <!-- <span class="icon-document"></span> -->
                <a class="name" [href]="downloadAttachment(attachment.name)">{{
                    attachment.name
                }}</a>
                <span class="size">({{ getFormattedSize(attachment.size) }})</span>
                <span
                    class="icon-bin"
                    *ngIf="isEditing()"
                    (click)="deleteAttachment(attachment.name)"
                    tooltip="Supprimer la pièce jointe"
                    tooltipPosition="left"
                    tooltipColor="#955099"
                ></span>
            </div>
            <div class="attachment-container" *ngFor="let attachment of fileToAdd">
                <!-- <span class="icon-document"></span> -->
                <span class="name">{{ attachment.name }}</span>
                <span class="size">({{ getFormattedSize(attachment.size) }})</span>
                <span
                    class="icon-close"
                    (click)="removeAttachment(attachment.name)"
                    tooltip="Supprimer la pièce jointe"
                    tooltipPosition="left"
                    tooltipColor="#955099"
                ></span>
            </div>
        </div>
        <div class="modified-owner" *ngIf="lastLog && !isEditing()">
            <p class="modified-owner" [ngClass]="{ purple: lastLog.id !== post.authorid }">
                Ce message a été modifié le {{ lastLog.fullDate }} par {{ lastLog.fullName }}
            </p>
        </div>
    </div>
</div>
<div class="separator" *ngIf="!isQuote"></div>
