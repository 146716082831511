<div class="container">
    <div class="icon" [ngClass]="getIconStyle()"></div>
    <div class="name">
        {{ getElementName() }}<span class="author" *ngIf="element.owner"> (auteur.e)</span>
    </div>
    <div class="role">
        <span
            class="icon icon-droit-lecture"
            *ngIf="isReader()"
            tooltip="Lecture seule"
            tooltipPosition="left"
            tooltipColor="#955099"
        ></span>
        <span
            class="icon icon-droit-ecriture"
            *ngIf="isWriter()"
            tooltip="Lecture / Ecriture"
            tooltipPosition="left"
            tooltipColor="#955099"
        ></span>
        <span
            class="icon icon-droit-moderation"
            *ngIf="isModerator()"
            tooltip="Modérateur"
            tooltipPosition="left"
            tooltipColor="#955099"
        ></span>
    </div>
    <div
        class="icon-down"
        [ngClass]="{ opened: isGroupOpened() }"
        *ngIf="type === 'group'"
        (click)="showGroup()"
    ></div>
    <div
        class="group-container"
        *ngIf="type === 'group'"
        [@slideToggle]="openedGroup ? 'open' : 'closed'"
    >
        <div class="name-container" *ngFor="let user of element.users">
            <span [ngClass]="getUserIconStyle(user)"></span>
            <span class="name">{{ user.lastname.toUpperCase() }} {{ user.firstname }}</span>
        </div>
    </div>
</div>
