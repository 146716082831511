<div class="subject-container">
    <div class="post-container">
        <div #scrollBarContainer class="scrollbar-container">
            <div class="post-list" *ngIf="subject?.posts.length">
                <app-post
                    *ngFor="let post of subject?.posts"
                    [post]="post"
                    [forum]="subject.hierarchy.forum"
                    (quote)="quotePost($event)"
                ></app-post>
            </div>
            <div class="separator" *ngIf="subject?.posts.length"></div>
            <div class="response-container" *ngIf="canCreatePost()">
                <div *ngIf="quote" class="quote">
                    <app-post
                        [post]="quote"
                        [forum]="subject.hierarchy.forum"
                        [isQuote]="true"
                        (quote)="removeQuote()"
                    ></app-post>
                </div>
                <div class="input-container">
                    <div
                        id="input"
                        #input
                        contenteditable
                        [ngClass]="{ placeholder: !answer.length }"
                        [textContent]="answer"
                        (input)="answer = $event.target.textContent"
                        (keyup.enter)="sendPost()"
                    ></div>
                    <span class="icon-Envoyer" (click)="sendPost()"></span>
                    <span class="markdown" *ngIf="!answer.length"
                        >(<strong>*gras*</strong>, <em>_italique_</em>)</span
                    >
                </div>
                <div
                    class="upload-container"
                    (click)="openUploadFilesDialog()"
                    *ngIf="answer.length"
                >
                    <div class="icon-Importer"></div>
                </div>
                <div class="file-container" *ngIf="files.length > 0">
                    <div class="attachment-container" *ngFor="let file of files; index as i">
                        <!-- <span class="icon-document"></span> -->
                        <span class="name">{{ file.name }}</span>
                        <span class="size">({{ getFormattedSize(file.size) }})</span>
                        <span class="icon-close" (click)="removeAttachment(i)"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
