import {
    Component,
    OnInit,
    EventEmitter,
    Output,
    OnDestroy,
    ViewChild,
    ElementRef
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

import { ForumService } from '../../services/forum.service';
import { DialogService } from '../../services/dialog.service';
import { PermissionService } from '../../services/permission.service';
import { LoginService } from '../../services/login.service';

import { Forum } from '../../structures/forum';
import { Subscription } from 'rxjs';
import { slideToggle } from 'src/app/animations/slide-toggle.animation';

/**
 * Composant gérant l'affichage du menu de gauche
 */
@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss'],
    animations: [slideToggle]
})
export class NavComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    refreshAllForums$: Subscription;
    disabledForums$: Subscription;
    userReady$: Subscription;
    unarchivedForums$: Subscription;
    unmaskedForums$: Subscription;
    maskedForums$: Subscription;
    getForums$: Subscription;
    deletedForums$: Subscription;
    routeQueryParams$: Subscription;

    @Output() select: EventEmitter<any> = new EventEmitter();
    @Output() resize: EventEmitter<boolean> = new EventEmitter();

    search: string;
    activeSearch: boolean;
    @ViewChild('searchElement') searchElement: ElementRef;

    authorsForums: Array<Forum>;
    membersForums: Array<Forum>;
    disabledForums: Array<Forum>;

    selectedForum: number;

    startedForumsCount: number;
    memberForumsCount: number;
    totalForumsCount: number;
    disabledForumsCount: number;

    isClosedAuthorForums = false;
    isClosedMemberForums = false;
    isClosedDisabledForums = false;

    reduced = false;
    user: any;

    constructor(
        private forumService: ForumService,
        private permissionService: PermissionService,
        private dialogService: DialogService,
        private loginService: LoginService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit() {
        if (this.userReady$) {
            this.userReady$.unsubscribe();
        }
        this.userReady$ = this.loginService.userReady.subscribe(
            () => {
                if (this.routeQueryParams$) {
                    this.routeQueryParams$.unsubscribe();
                }
                this.routeQueryParams$ = this.route.queryParams.subscribe((params) => {
                    if (params['new']) {
                        if (params['user']) {
                            this.dialogService.openCreateForumDialog(
                                false,
                                null,
                                null,
                                +params['user']
                            );
                        } else if (params['group']) {
                            this.dialogService.openCreateForumDialog(
                                false,
                                null,
                                +params['group'],
                                null
                            );
                        } else {
                            this.dialogService.openCreateForumDialog(false);
                        }
                    } else if (params['subscribe']) {
                        if (params['forum']) {
                            this.dialogService.openSubscribeForumDialog(+params['forum']);
                        }
                    } else if (params['shared']) {
                        if (params['type'] && params['id']) {
                            this.dialogService
                                .openSharedForumDialog(params['type'], params['id'])
                                .subscribe((data: Forum | undefined) => {
                                    if (data) {
                                        this.selectForum(data.id);
                                        this.router.navigate(['forum', data.id]);
                                    }
                                });
                        }
                    }
                });
                this.subscriptions.add(this.routeQueryParams$);

                this.search = '';
                this.totalForumsCount = 0;
                this.refreshData();
                this.toggleMemberForums();
                this.toggleDisabledForums();
                this.refreshAllForums$ = this.forumService.refreshAllForums.subscribe(() => {
                    this.refreshData();
                });
                this.subscriptions.add(this.refreshAllForums$);

                this.maskedForums$ = this.forumService.maskedForum.subscribe((forum: Forum) => {
                    forum.hidden = true;
                    this.membersForums.splice(this.membersForums.indexOf(forum), 1);
                    this.disabledForums.push(forum);
                    this.disabledForumsCount++;
                    this.memberForumsCount--;
                });
                this.subscriptions.add(this.maskedForums$);

                this.unmaskedForums$ = this.forumService.unmaskedForum.subscribe((forum: Forum) => {
                    forum.hidden = false;
                    this.disabledForums.splice(this.disabledForums.indexOf(forum), 1);
                    this.membersForums.push(forum);
                    this.disabledForumsCount--;
                    this.memberForumsCount++;
                });
                this.subscriptions.add(this.unmaskedForums$);

                this.unarchivedForums$ = this.forumService.unarchivedForum.subscribe(
                    (forum: Forum) => {
                        forum.archived = false;
                        this.disabledForums.splice(this.disabledForums.indexOf(forum), 1);
                        this.disabledForumsCount--;
                        if (forum.ownerid === this.loginService.getUser().id) {
                            this.authorsForums.push(forum);
                            this.startedForumsCount++;
                        } else {
                            this.membersForums.push(forum);
                            this.memberForumsCount++;
                        }
                    }
                );
                this.subscriptions.add(this.unarchivedForums$);

                this.disabledForums$ = this.forumService.disabledForum.subscribe(
                    (forumId: number) => {
                        for (const i in this.authorsForums) {
                            if (this.authorsForums[i].id === forumId) {
                                this.authorsForums[i].archived = true;
                                this.disabledForums.push(
                                    this.authorsForums.splice(parseInt(i, 10), 1)[0]
                                );
                                this.disabledForumsCount++;
                            }
                        }
                        for (const i in this.membersForums) {
                            if (this.membersForums[i].id === forumId) {
                                this.membersForums[i].archived = true;
                                this.disabledForums.push(
                                    this.membersForums.splice(parseInt(i, 10), 1)[0]
                                );
                                this.disabledForumsCount++;
                            }
                        }
                    }
                );
                this.subscriptions.add(this.disabledForums$);

                this.deletedForums$ = this.forumService.deletedForum.subscribe(
                    (forumId: number) => {
                        for (const i in this.disabledForums) {
                            if (this.disabledForums[i].id === forumId) {
                                this.disabledForums.splice(parseInt(i, 10), 1);
                                this.disabledForumsCount--;
                            }
                        }
                    }
                );
                this.subscriptions.add(this.deletedForums$);
            },
            (error: HttpErrorResponse) => {
                this.dialogService.openErrorDialog(error.error.userMessage);
            }
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    /**
     * Rafraichit la liste des forums de l'utilisateur
     */
    refreshData() {
        this.getForums$ = this.forumService.getForums(this.search).subscribe(
            (data: any) => {
                this.authorsForums = data.mine;
                this.startedForumsCount = data.mine.length;

                this.membersForums = data.member;
                this.memberForumsCount = data.member.length;

                this.totalForumsCount = this.memberForumsCount + this.startedForumsCount;

                this.disabledForums = data.archived.filter(
                    (forum) => forum.owner.id === this.loginService.getUser().id || !forum.archived
                );
                this.disabledForumsCount = this.disabledForums.length;
            },
            (error: HttpErrorResponse) => {
                if (error.error.errorCode !== 'USER_NOT_AUTH') {
                    this.dialogService.openErrorDialog(error.error.userMessage);
                }
            }
        );
        this.subscriptions.add(this.getForums$);
    }

    /**
     * Déplie/replie la liste des forums dont l'utilisateur est auteur
     */
    toggleAuthorForums() {
        this.isClosedAuthorForums = !this.isClosedAuthorForums;
        if (!this.isClosedAuthorForums && !this.isClosedMemberForums) {
            this.toggleMemberForums();
        }
        if (!this.isClosedAuthorForums && !this.isClosedDisabledForums) {
            this.toggleDisabledForums();
        }
    }

    /**
     * Déplie/replie la liste des forums dont l'utilisateur est membre
     */
    toggleMemberForums() {
        this.isClosedMemberForums = !this.isClosedMemberForums;
        if (!this.isClosedMemberForums && !this.isClosedAuthorForums) {
            this.toggleAuthorForums();
        }
        if (!this.isClosedMemberForums && !this.isClosedDisabledForums) {
            this.toggleDisabledForums();
        }
    }

    /**
     * Déplie/replie la liste des forums désactivés
     */
    toggleDisabledForums() {
        this.isClosedDisabledForums = !this.isClosedDisabledForums;
        if (!this.isClosedDisabledForums && !this.isClosedAuthorForums) {
            this.toggleAuthorForums();
        }
        if (!this.isClosedDisabledForums && !this.isClosedMemberForums) {
            this.toggleMemberForums();
        }
    }

    toggleReduce() {
        this.reduced = !this.reduced;
        this.resize.emit(this.reduced);
    }

    /**
     * Sélectionne un forum à afficher dans la vue principal
     */
    selectForum(idForum: number) {
        this.forumService.setSearchTerm('');
        this.selectedForum = idForum;
        this.select.emit(idForum);
    }

    /**
     * Ouvre la fenêtre de création de forum
     */
    openSelectForumDialog() {
        this.dialogService.openSelectForumDialog();
    }

    /**
     * Ouvre la fenêtre d'enregistrement à un forum
     */
    openSubscribeForumDialog() {
        this.dialogService.openSubscribeForumDialog();
    }

    toggleActiveSearch() {
        if (!this.activeSearch) {
            this.activeSearch = true;
            setTimeout(() => {
                this.searchElement.nativeElement.focus();
            });
        } else {
            setTimeout(() => {
                this.activeSearch = false;
            }, 100);
        }
    }

    isSearchEmpty() {
        if (this.search) {
            return this.search.length === 0;
        }
        return true;
    }

    refreshDataFromSearch() {
        this.refreshData();
        if (this.activeSearch && !this.search) {
            this.toggleActiveSearch();
        }
    }

    resetSearch($event: Event): void {
        $event.preventDefault();
        $event.stopImmediatePropagation();
        this.search = '';
        this.activeSearch = false;
        this.refreshData();
    }

    /**
     * Indique si la bouton permettant d'ouvrir la fenetre de création de forum doit être affiché
     * @returns {Boolean} True si le bouton doit être affiché, faux dans le cas contraire
     */
    canCreateForum(): boolean {
        return this.permissionService.canCreateForum();
    }
}
