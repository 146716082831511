import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ForumComponent } from './components/main/forum/forum.component';
import { SubjectComponent } from './components/main/subject/subject.component';
import { ThemeComponent } from './components/main/theme/theme.component';
import { SubthemeComponent } from './components/main/subtheme/subtheme.component';

import { CarouselComponent } from './components/carousel/carousel.component';

const appRoutes: Routes = [
    {
        path: 'forum/:id',
        component: ForumComponent
    },
    {
        path: 'theme/:id',
        component: ThemeComponent
    },
    {
        path: 'subtheme/:id',
        component: SubthemeComponent
    },
    {
        path: 'subject/:id',
        component: SubjectComponent
    },
    {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { useHash: true })],
    exports: [RouterModule],
    providers: []
})
export class AppRoutingModule {}
