import { Component, OnInit, Inject, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription, Subject } from 'rxjs';

import { UsersService } from '../../services/users.service';
import { ForumService } from '../../services/forum.service';
import { LoginService } from '../../services/login.service';

import { Group } from '../../structures/group';
import { User } from '../../structures/user';
import { Role } from '../../structures/role';

@Component({
    selector: 'app-create-forum',
    templateUrl: './create-forum.component.html',
    styleUrls: ['./create-forum.component.scss']
})
export class CreateForumComponent implements OnInit, OnDestroy {
    constructor(
        public dialogRef: MatDialogRef<CreateForumComponent>,
        private usersService: UsersService,
        private forumService: ForumService,
        private loginService: LoginService,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {}

    getLearners$: Subscription;
    getLearnersCount$: Subscription;
    getTeachers$: Subscription;
    getTeachersCount$: Subscription;
    getLearnerGroups$: Subscription;
    getTeacherGroups$: Subscription;
    getUsers$: Subscription;
    getLearnerGroupsCount$: Subscription;
    getTeacherGroupsCount$: Subscription;
    getUsersCount$: Subscription;
    action$: Subscription;
    private subscriptions = new Subscription();

    @Output() openErrorDialog: EventEmitter<string> = new EventEmitter();

    LIMIT = 30;

    structures: Array<any>;
    selectedStructure: any;

    searchTerm: string;
    searchTermValidated: string;

    forum: any;
    creationDone: boolean;

    currentTab: String;
    currentNav: string;

    learnerGroups: Array<Group>;
    totalLearnerGroupNumber: number;
    currentPageLearnerGroup: number;

    teacherGroups: Array<Group>;
    totalTeacherGroupNumber: number;
    currentPageTeacherGroup: number;

    learners: Array<User>;
    totalLearnerNumber: number;
    currentPageLearner: number;

    teachers: Array<User>;
    totalTeacherNumber: number;
    currentPageTeacher: number;

    roles: Array<Role>;

    headerLabel: string;
    footerLabel: string;

    showDescription: boolean;

    ngOnInit() {
        this.forum = {};
        this.creationDone = false;

        this.forum.public = this.data.type;

        if (this.showGroupTab()) {
            this.currentTab = 'learner-group';
        } else {
            this.currentTab = 'learner';
        }
        this.currentNav = 'users';

        this.currentPageLearnerGroup = -1;
        this.currentPageTeacherGroup = -1;
        this.currentPageLearner = -1;
        this.currentPageTeacher = -1;

        this.showDescription = false;
        this.headerLabel = this.data.forumId ? 'Modifier le forum' : 'Nouveau forum';
        this.footerLabel = this.data.forumId ? 'Modifier le forum' : 'Créer le forum';

        this.subscriptions.add(
            this.loginService.updateStructure.subscribe((data) => {
                this.selectedStructure = data;
            })
        );

        this.subscriptions.add(
            this.usersService.getStructures().subscribe(
                (data) => {
                    this.structures = data.data.structures.map((structure: any) => {
                        return {
                            key: structure.id,
                            title: structure.name
                        };
                    });
                    this.structures.unshift({ key: 0, title: 'Administration nationale' });
                    this.learnerGroups = [];
                    this.teacherGroups = [];
                    this.learners = [];
                    this.teachers = [];
                    this.roles = [];

                    if (this.loginService.isNationalAdmin()) {
                        this.selectedStructure = undefined;
                    } else {
                        const structure = {
                            name: this.loginService.getUser().localStructure,
                            id: this.loginService.getUser().structureid
                        };
                        this.selectedStructure = structure;
                    }
                    if (this.data.forumId) {
                        this.subscriptions.add(
                            this.forumService.getForum(this.data.forumId).subscribe(
                                (forum: any) => {
                                    this.forum = forum;
                                    this.initUsersSelected();
                                    this.refreshData(true);
                                },
                                (error: HttpErrorResponse) => {
                                    this.openErrorDialog.emit(error.error.userMessage);
                                }
                            )
                        );
                    } else {
                        this.forum.users = [];
                        this.forum.groups = [];
                        this.forum.roles = [];
                        this.forum.description = '';

                        if (this.data.userId) {
                            this.getUser(this.data.userId).subscribe((user) => {
                                this.forum.users.push({
                                    id: user.id,
                                    firstname: user.firstname,
                                    lastname: user.lastname.toUpperCase(),
                                    role: 'attendee',
                                    rights: 1,
                                    roles: user.roles
                                });
                                this.refreshData(true);
                                this.initUsersSelected();
                            });
                        } else if (this.data.groupId) {
                            this.getGroup(this.data.groupId).subscribe((group) => {
                                this.forum.groups.push({
                                    id: group.id,
                                    name: group.name,
                                    role: 'attendee',
                                    rights: 1
                                });
                                this.refreshData(true);
                                this.initUsersSelected();
                            });
                        } else {
                            this.refreshData(true);
                        }
                    }
                },
                (error: HttpErrorResponse) => {
                    this.openErrorDialog.emit(error.error.userMessage);
                }
            )
        );
    }

    getUser(userId: any) {
        const user$: Subject<User> = new Subject();
        const timeStamp = Date.now();
        (document.getElementById(
            'header-container'
        ) as HTMLIFrameElement).contentWindow.postMessage(
            { getUser: true, userId, timeStamp },
            '*'
        );
        window.addEventListener('message', (event: MessageEvent) => {
            if (event.data.timeStamp === timeStamp) {
                user$.next(event.data.user);
            }
        });
        return user$.asObservable();
    }

    getGroup(groupId: any) {
        const group$: Subject<Group> = new Subject();
        const timeStamp = Date.now();
        (document.getElementById(
            'header-container'
        ) as HTMLIFrameElement).contentWindow.postMessage(
            { getGroup: true, params: { groupid: groupId }, timeStamp },
            '*'
        );
        window.addEventListener('message', (event: MessageEvent) => {
            if (event.data.timeStamp === timeStamp) {
                group$.next(event.data.group);
            }
        });
        return group$.asObservable();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    initUsersSelected() {
        for (const i in this.forum.groups) {
            if (this.forum.groups[i]) {
                this.forum.groups[i].selected = true;
                if (
                    this.forum.groups[i].role === 'moderator' &&
                    this.forum.groups[i].rights === 15
                ) {
                    this.forum.groups[i].role = 'moderator';
                } else if (
                    (this.forum.groups[i].role === 'attendee' ||
                        this.forum.groups[i].role === '') &&
                    this.forum.groups[i].rights >= 7
                ) {
                    this.forum.groups[i].role = 'writer';
                } else if (
                    (this.forum.groups[i].role === 'attendee' ||
                        this.forum.groups[i].role === '') &&
                    this.forum.groups[i].rights === 1
                ) {
                    this.forum.groups[i].role = 'reader';
                }
                if (this.forum.groups[i].type === 'teacher') {
                    this.teacherGroups.unshift(this.forum.groups[i]);
                } else {
                    this.learnerGroups.unshift(this.forum.groups[i]);
                }
            }
        }
        for (const i in this.forum.users) {
            if (this.forum.users[i].roles.learner || this.forum.users[i].roles.prospect) {
                this.forum.users[i].selected = true;
                if (this.forum.users[i].role === 'moderator' && this.forum.users[i].rights === 15) {
                    this.forum.users[i].role = 'moderator';
                } else if (
                    (this.forum.users[i].role === 'attendee' || this.forum.users[i].role === '') &&
                    this.forum.users[i].rights >= 7
                ) {
                    this.forum.users[i].role = 'writer';
                } else if (
                    (this.forum.users[i].role === 'attendee' || this.forum.users[i].role === '') &&
                    this.forum.users[i].rights === 1
                ) {
                    this.forum.users[i].role = 'reader';
                }
                this.learners.unshift(this.forum.users[i]);
                // } else if (this.forum.users[i].id !== this.forum.ownerid) {
            } else {
                this.forum.users[i].selected = true;
                if (this.forum.users[i].role === 'moderator' && this.forum.users[i].rights === 15) {
                    this.forum.users[i].role = 'moderator';
                } else if (
                    (this.forum.users[i].role === 'attendee' || this.forum.users[i].role === '') &&
                    this.forum.users[i].rights >= 7
                ) {
                    this.forum.users[i].role = 'writer';
                } else if (
                    (this.forum.users[i].role === 'attendee' || this.forum.users[i].role === '') &&
                    this.forum.users[i].rights === 1
                ) {
                    this.forum.users[i].role = 'reader';
                }
                this.teachers.unshift(this.forum.users[i]);
            }
        }
        for (const i in this.forum.roles) {
            if (this.forum.roles[i]) {
                this.forum.roles[i].selected = true;
                if (this.forum.roles[i].role === 'moderator' && this.forum.roles[i].rights === 15) {
                    this.forum.roles[i].role = 'moderator';
                } else if (
                    (this.forum.roles[i].role === 'attendee' || this.forum.roles[i].role === '') &&
                    this.forum.roles[i].rights >= 7
                ) {
                    this.forum.roles[i].role = 'writer';
                } else if (
                    (this.forum.roles[i].role === 'attendee' || this.forum.roles[i].role === '') &&
                    this.forum.roles[i].rights === 1
                ) {
                    this.forum.roles[i].role = 'reader';
                }
                let existingRoles = false;
                for (const j in this.roles) {
                    if (this.forum.roles[i].id === this.roles[j].id) {
                        existingRoles = true;
                        this.roles[j].structureid.push(this.forum.roles[i].structureid);
                    }
                }
                if (!existingRoles) {
                    this.forum.roles[i].structureid = [this.forum.roles[i].structureid];
                    this.roles.unshift(this.forum.roles[i]);
                }
            }
        }
    }

    /**
     * Affiche la page des groupes suivante
     */
    nextPageLearnerGroup(newSearch: boolean) {
        this.currentPageLearnerGroup++;

        const params: any = {};

        if (this.isNationalAdmin()) {
            if (this.selectedStructure) {
                params.structureid = this.selectedStructure.id;
            }
        } else {
            params.structureid = this.loginService.getUser().structureid;
        }
        params.search = this.searchTerm ? this.searchTerm : '';
        params.type = 'learner';
        params.offset = this.currentPageLearnerGroup * this.LIMIT;
        params.limit = this.LIMIT;

        if (this.getLearnerGroups$) {
            this.getLearnerGroups$.unsubscribe();
        }
        this.getLearnerGroups$ = this.usersService.getGroups(params).subscribe(
            (data) => {
                if (newSearch) {
                    const learnerGroupsTmp = [];
                    for (const i in this.learnerGroups) {
                        if (this.learnerGroups[i].selected === true) {
                            learnerGroupsTmp.push(this.learnerGroups[i]);
                        }
                    }
                    this.learnerGroups = learnerGroupsTmp;
                }

                for (const i in data) {
                    if (data[i]) {
                        let found = false;
                        for (const j in this.learnerGroups) {
                            if (data[i].id === this.learnerGroups[j].id) {
                                found = true;
                            }
                        }
                        if (!found) {
                            this.learnerGroups = this.learnerGroups.concat(data[i]);
                        }
                    }
                }
            },
            (error: HttpErrorResponse) => {
                this.openErrorDialog.emit(error.error.userMessage);
            }
        );
        this.subscriptions.add(this.getLearnerGroups$);
    }

    /**
     * Affiche la page des groupes suivante
     */
    nextPageTeacherGroup(newSearch: boolean) {
        this.currentPageTeacherGroup++;

        const params: any = {};

        if (this.isNationalAdmin()) {
            if (this.selectedStructure) {
                params.structureid = this.selectedStructure.id;
            }
        } else {
            params.structureid = this.loginService.getUser().structureid;
        }
        params.search = this.searchTerm ? this.searchTerm : '';
        params.type = 'teacher';
        params.offset = this.currentPageTeacherGroup * this.LIMIT;
        params.limit = this.LIMIT;

        if (this.getTeacherGroups$) {
            this.getTeacherGroups$.unsubscribe();
        }
        this.getTeacherGroups$ = this.usersService.getGroups(params).subscribe(
            (data) => {
                if (newSearch) {
                    const teacherGroupsTmp = [];
                    for (const i in this.teacherGroups) {
                        if (this.teacherGroups[i].selected === true) {
                            teacherGroupsTmp.push(this.teacherGroups[i]);
                        }
                    }
                    this.teacherGroups = teacherGroupsTmp;
                }

                for (const i in data) {
                    if (data[i]) {
                        let found = false;
                        for (const j in this.teacherGroups) {
                            if (data[i].id === this.teacherGroups[j].id) {
                                found = true;
                            }
                        }
                        if (!found) {
                            this.teacherGroups = this.teacherGroups.concat(data[i]);
                        }
                    }
                }
            },
            (error: HttpErrorResponse) => {
                this.openErrorDialog.emit(error.error.userMessage);
            }
        );
        this.subscriptions.add(this.getTeacherGroups$);
    }

    /**
     * Affiche la page des apprenants suivante
     */
    nextPageLearner(newSearch: boolean) {
        this.currentPageLearner++;

        const params: any = {};

        if (this.isNationalAdmin()) {
            if (this.selectedStructure) {
                params.structureid = this.selectedStructure.id;
            }
        } else {
            params.structureid = this.loginService.getUser().structureid;
        }
        params.search = this.searchTerm ? this.searchTerm : '';
        params.role = 'learner|prospect';
        params.offset = this.currentPageLearner * this.LIMIT;
        params.limit = this.LIMIT;

        if (this.getLearners$) {
            this.getLearners$.unsubscribe();
        }
        this.getLearners$ = this.usersService.getUsers(params).subscribe(
            (data) => {
                if (this.data.forumId) {
                    for (const i in data) {
                        if (data[i].id === this.forum.ownerid) {
                            data.splice(i, 1);
                        }
                    }
                }
                for (const i in data) {
                    if (data[i].id === this.loginService.getUser().id) {
                        data.splice(i, 1);
                    }
                }

                if (newSearch) {
                    const learnersTmp = [];
                    for (const i in this.learners) {
                        if (this.learners[i].selected === true) {
                            learnersTmp.push(this.learners[i]);
                        }
                    }
                    this.learners = learnersTmp;
                }

                for (const i in data) {
                    if (data[i]) {
                        let found = false;
                        for (const j in this.learners) {
                            if (data[i].id === this.learners[j].id) {
                                found = true;
                            }
                        }
                        if (!found) {
                            this.learners = this.learners.concat(data[i]);
                        }
                    }
                }
            },
            (error: HttpErrorResponse) => {
                this.openErrorDialog.emit(error.error.userMessage);
            }
        );
        this.subscriptions.add(this.getLearners$);
    }

    /**
     * Affiche la page des formateurs suivante
     */
    nextPageTeacher(newSearch: boolean) {
        this.currentPageTeacher++;

        const params: any = {};

        if (this.isNationalAdmin()) {
            if (this.selectedStructure) {
                params.structureid = this.selectedStructure.id;
            }
        } else {
            params.structureid = this.loginService.getUser().structureid;
        }
        params.search = this.searchTerm ? this.searchTerm : '';
        params.offset = this.currentPageTeacher * this.LIMIT;
        params.limit = this.LIMIT;
        params.role =
            'internalTeacher|externalTeacher|siteTeacher|corporationTeacher|tutor|localAdmin|nationalAdmin';

        if (this.getTeachers$) {
            this.getTeachers$.unsubscribe();
        }
        this.getTeachers$ = this.usersService.getUsers(params).subscribe(
            (data) => {
                if (this.data.forumId) {
                    for (const i in data) {
                        if (data[i].id === this.forum.ownerid) {
                            data.splice(i, 1);
                        }
                    }
                }
                for (const i in data) {
                    if (data[i].id === this.loginService.getUser().id) {
                        data.splice(i, 1);
                    }
                }

                if (newSearch) {
                    const teachersTmp = [];
                    for (const i in this.teachers) {
                        if (this.teachers[i].selected === true) {
                            teachersTmp.push(this.teachers[i]);
                        }
                    }
                    this.teachers = teachersTmp;
                }

                for (const i in data) {
                    if (data[i]) {
                        let found = false;
                        for (const j in this.teachers) {
                            if (data[i].id === this.teachers[j].id) {
                                found = true;
                            }
                        }
                        if (!found) {
                            this.teachers = this.teachers.concat(data[i]);
                        }
                    }
                }
            },
            (error: HttpErrorResponse) => {
                this.openErrorDialog.emit(error.error.userMessage);
            }
        );
        this.subscriptions.add(this.getTeachers$);
    }

    /**
     * Récupère le nombre de groupes dans la structure actuelle
     */
    getLearnerGroupNumber() {
        const params: any = {};

        if (this.isNationalAdmin()) {
            if (this.selectedStructure) {
                params.structureid = this.selectedStructure.id;
            }
        } else {
            params.structureid = this.loginService.getUser().structureid;
        }
        params.type = 'learner';
        params.search = this.searchTerm ? this.searchTerm : '';

        if (this.getLearnerGroupsCount$) {
            this.getLearnerGroupsCount$.unsubscribe();
        }
        this.getLearnerGroupsCount$ = this.usersService
            .getGroupsCount(params)
            .subscribe((data: any) => {
                this.totalLearnerGroupNumber = data.count;
            });
        this.subscriptions.add(this.getLearnerGroupsCount$);
    }

    /**
     * Récupère le nombre de groupes dans la structure actuelle
     */
    getTeacherGroupNumber() {
        const params: any = {};

        if (this.isNationalAdmin()) {
            if (this.selectedStructure) {
                params.structureid = this.selectedStructure.id;
            }
        } else {
            params.structureid = this.loginService.getUser().structureid;
        }
        params.type = 'teacher';
        params.search = this.searchTerm ? this.searchTerm : '';

        if (this.getTeacherGroupsCount$) {
            this.getTeacherGroupsCount$.unsubscribe();
        }
        this.getTeacherGroupsCount$ = this.usersService
            .getGroupsCount(params)
            .subscribe((data: any) => {
                this.totalTeacherGroupNumber = data.count;
            });
        this.subscriptions.add(this.getTeacherGroupsCount$);
    }

    /**
     * Récupère le nombre d'apprenants dans la structure actuelle
     */
    getLearnerNumber() {
        const params: any = {};

        if (this.isNationalAdmin()) {
            if (this.selectedStructure) {
                params.structureid = this.selectedStructure.id;
            }
        } else {
            params.structureid = this.loginService.getUser().structureid;
        }
        params.role = 'learner|prospect';
        params.search = this.searchTerm ? this.searchTerm : '';

        if (this.getLearnersCount$) {
            this.getLearnersCount$.unsubscribe();
        }
        this.getLearnersCount$ = this.usersService.getUsersCount(params).subscribe((data: any) => {
            this.totalLearnerNumber = data.count;
        });
        this.subscriptions.add(this.getLearnersCount$);
    }

    /**
     * Récupère le nombre de formateurs dans la structure actuelle
     */
    getTeacherNumber() {
        const params: any = {};

        if (this.isNationalAdmin()) {
            if (this.selectedStructure) {
                params.structureid = this.selectedStructure.id;
            }
        } else {
            params.structureid = this.loginService.getUser().structureid;
        }
        params.role =
            'internalTeacher|externalTeacher|siteTeacher|corporationTeacher|tutor|localAdmin|nationalAdmin';
        params.search = this.searchTerm ? this.searchTerm : '';

        if (this.getTeachersCount$) {
            this.getTeachersCount$.unsubscribe();
        }
        this.getTeachersCount$ = this.usersService.getUsersCount(params).subscribe((data: any) => {
            this.totalTeacherNumber = data.count;
        });
        this.subscriptions.add(this.getTeachersCount$);
    }

    getCurrentLearnerGroupNumber(): number {
        if (this.learnerGroups) {
            return this.learnerGroups.filter((l) => l.selected).length;
        }
        return 0;
    }

    getCurrentTeacherGroupNumber(): number {
        if (this.teacherGroups) {
            return this.teacherGroups.filter((l) => l.selected).length;
        }
        return 0;
    }

    getCurrentLearnerNumber(): number {
        if (this.learners) {
            return this.learners.filter((l) => l.selected).length;
        }
        return 0;
    }

    getCurrentTeacherNumber(): number {
        if (this.teachers) {
            return this.teachers.filter((l) => l.selected).length;
        }
        return 0;
    }

    /**
     * retourne le nombre total de groupes sélectionnés, y compris ceux qui ne sont pas visibles dans le cas d'une recherche
     */
    getTotalCurrentLearnerGroupNumber(): number {
        if (this.learnerGroups !== undefined) {
            return this.learnerGroups.filter((g) => g.selected).length;
        } else {
            return 0;
        }
    }
    /**
     * retourne le nombre total de groupes sélectionnés, y compris ceux qui ne sont pas visibles dans le cas d'une recherche
     */
    getTotalCurrentTeacherGroupNumber(): number {
        if (this.teacherGroups !== undefined) {
            return this.teacherGroups.filter((g) => g.selected).length;
        } else {
            return 0;
        }
    }

    getTotalCurrentLearnerNumber(): number {
        if (this.learners !== undefined) {
            return this.learners.filter((l) => l.selected).length;
        } else {
            return 0;
        }
    }

    getTotalCurrentTeacherNumber(): number {
        if (this.teachers !== undefined) {
            return this.teachers.filter((t) => t.selected).length;
        } else {
            return 0;
        }
    }

    updateStructure($event: any) {
        setTimeout(() => {
            this.structures = $event;
            if (this.structures.some((s) => s.selected)) {
                for (const i in this.structures) {
                    if (this.structures[i].selected) {
                        this.selectedStructure = {
                            id: this.structures[i].key,
                            name: this.structures[i].title
                        };
                    }
                }
            } else if (this.isNationalAdmin()) {
                this.selectedStructure = undefined;
            }
            this.refreshData(true);
        });
    }

    /**
     * Rafraichit la liste des utilisateurs et des groupes
     */
    refreshData(newSearch: boolean) {
        this.currentPageTeacherGroup = -1;
        this.currentPageLearnerGroup = -1;
        this.currentPageLearner = -1;
        this.currentPageTeacher = -1;

        if (
            this.selectedStructure &&
            this.selectedStructure.id !== undefined &&
            this.selectedStructure.id !== 0
        ) {
            this.loginService.updateLastStructure(this.selectedStructure);
        }
        if (this.showGroupTab()) {
            this.nextPageLearnerGroup(newSearch);
            this.getLearnerGroupNumber();
            this.nextPageTeacherGroup(newSearch);
            this.getTeacherGroupNumber();
        }
        this.nextPageLearner(newSearch);
        this.getLearnerNumber();
        this.nextPageTeacher(newSearch);
        this.getTeacherNumber();
        this.getRoles();

        // Activation du pipe/filtrage direct à l'affichage pour les learners sélectionnés
        this.searchTermValidated = this.searchTerm;
    }

    /**
     * Affiche le champ permettant de renseigner une description
     */
    toggleDescription() {
        this.showDescription = true;
    }

    /**
     * Indique si le menu déroulant des structures doit s'afficher pour l'utilisateur actuel
     * @returns {boolean} True si le menu doit s'afficher, false dans le cas contraire
     */
    isNationalAdmin(): boolean {
        return this.loginService.isNationalAdmin();
    }

    /**
     * Ferme la fenêtre de création de forum
     */
    closeDialog() {
        this.dialogRef.close();
    }

    /**
     * @param {string} tab Le nom d'un onglet de la fenêtre de création de forum
     * Modifie l'onglet courant de la fenêtre
     */
    switchCurrentTab(tab: string) {
        this.currentTab = tab;
    }

    switchCurrentNav(nav: string) {
        this.currentNav = nav;
    }

    /**
     * @param {string} tab Le nom d'un onglet de la fenêtre de création de forum
     * Indique si l'onglet passé en paramètre est l'onglet courant
     * @returns {Boolean} True si l'onglet passé en paramètre est l'onglet courant, false dans le cas contraire
     */
    isCurrentTab(tab: string): boolean {
        return this.currentTab.localeCompare(tab) === 0;
    }

    /**
     * Indique si l'onglet passé en paramètre est l'onglet courant
     * @param {string} tab Le nom d'un onglet de la fenêtre de création de forum
     * @returns {Boolean} True si l'onglet passé en paramètre est l'onglet courant, false dans le cas contraire
     */
    isCurrentNav(nav: string): boolean {
        return this.currentNav.localeCompare(nav) === 0;
    }

    getRoles() {
        this.subscriptions.add(
            this.usersService.getRoles().subscribe((data: any) => {
                let roleFound: boolean;
                for (const i in data) {
                    if (data[i]) {
                        roleFound = false;
                        for (const j in this.roles) {
                            if (data[i].id === this.roles[j].id) {
                                roleFound = true;
                            }
                        }
                        if (!roleFound) {
                            this.roles.push(data[i]);
                        }
                    }
                }

                if (this.loginService.getUser().roles.localAdmin) {
                    for (const i in this.roles) {
                        if (this.roles[i].shortname === 'nationalAdmin') {
                            this.roles.splice(parseInt(i, 10), 1);
                        }
                    }
                }
            })
        );
    }

    /**
     * Recupere le nombre de roles actuellement affiches.
     */
    getCurrentRoleNumber() {
        let res = 0;
        for (const i in this.roles) {
            if (this.roles[i].selected) {
                res++;
            }
        }
        return res;
    }

    /**
     * Indique si l'utilisateur courant a le droit de voir l'onglet des groupes
     */
    showGroupTab() {
        return !(
            this.loginService.getUser().roles.corporationTeacher ||
            this.loginService.getUser().roles.siteTeacher
        );
    }

    showTeacherTab() {
        return !this.loginService.getUser().roles.tutor;
    }

    showRoleNav() {
        if (this.loginService.getUser()) {
            return (
                this.loginService.getUser().roles.nationalAdmin ||
                this.loginService.getUser().roles.localAdmin
            );
        }
    }

    /**
     * Crée/Met à jour le forum
     */
    createForum() {
        if (this.canCreateForum()) {
            this.creationDone = true;
            if (this.forum.description === undefined) {
                this.forum.description = '';
            }
            this.forum.groups = [];
            this.forum.users = [];
            this.forum.roles = [];
            this.formatMembers();
            const action = this.data.forumId
                ? this.forumService.updateForum(this.data.forumId, this.forum)
                : this.forumService.createForum(this.forum);
            if (this.action$) {
                this.action$.unsubscribe();
            }
            this.action$ = action.subscribe(
                (data) => {
                    this.forumService.emitRefreshForums();
                    this.closeDialog();
                },
                (error: HttpErrorResponse) => {
                    this.openErrorDialog.emit(error.error.userMessage);
                }
            );
            this.subscriptions.add(this.action$);
        }
    }

    /**
     * Indique si toutes les informations nécéssaires à la création du forum sont présentes
     * @returns {Boolean} True si le forum peut être crée, false dans le cas contraire
     */
    canCreateForum(): boolean {
        return this.forum.name !== undefined && this.forum.name !== '';
    }

    formatMembers(): void {
        for (const i in this.learnerGroups) {
            if (this.learnerGroups[i].selected) {
                let group: Group;
                if (this.learnerGroups[i].role === 'reader') {
                    group = {
                        id: this.learnerGroups[i].id,
                        role: '',
                        rights: 1
                    };
                } else if (
                    this.learnerGroups[i].role === 'writer' ||
                    this.learnerGroups[i].role === undefined
                ) {
                    group = {
                        id: this.learnerGroups[i].id,
                        role: '',
                        rights: 7
                    };
                } else if (this.learnerGroups[i].role === 'moderator') {
                    group = {
                        id: this.learnerGroups[i].id,
                        role: 'moderator',
                        rights: 15
                    };
                }
                this.forum.groups.push(group);
            }
        }

        for (const i in this.teacherGroups) {
            if (this.teacherGroups[i].selected) {
                let group: Group;
                if (this.teacherGroups[i].role === 'reader') {
                    group = {
                        id: this.teacherGroups[i].id,
                        role: '',
                        rights: 1
                    };
                } else if (
                    this.teacherGroups[i].role === 'writer' ||
                    this.teacherGroups[i].role === undefined
                ) {
                    group = {
                        id: this.teacherGroups[i].id,
                        role: '',
                        rights: 7
                    };
                } else if (this.teacherGroups[i].role === 'moderator') {
                    group = {
                        id: this.teacherGroups[i].id,
                        role: 'moderator',
                        rights: 15
                    };
                }
                this.forum.groups.push(group);
            }
        }

        for (const i in this.learners) {
            if (this.learners[i].selected) {
                let learner: User;
                if (!this.learners[i].owner) {
                    if (this.learners[i].role === 'reader') {
                        learner = {
                            id: this.learners[i].id,
                            role: '',
                            rights: 1
                        };
                    } else if (
                        this.learners[i].role === 'writer' ||
                        this.learners[i].role === undefined
                    ) {
                        learner = {
                            id: this.learners[i].id,
                            role: '',
                            rights: 7
                        };
                    } else if (this.learners[i].role === 'moderator') {
                        learner = {
                            id: this.learners[i].id,
                            role: 'moderator',
                            rights: 15
                        };
                    }
                    this.forum.users.push(learner);
                }
            }
        }

        for (const i in this.teachers) {
            if (this.teachers[i].selected) {
                let teacher: User;
                if (!this.teachers[i].owner) {
                    if (this.teachers[i].role === 'reader') {
                        teacher = {
                            id: this.teachers[i].id,
                            role: '',
                            rights: 1
                        };
                    } else if (
                        this.teachers[i].role === 'writer' ||
                        this.teachers[i].role === undefined
                    ) {
                        teacher = {
                            id: this.teachers[i].id,
                            role: '',
                            rights: 7
                        };
                    } else if (this.teachers[i].role === 'moderator') {
                        teacher = {
                            id: this.teachers[i].id,
                            role: 'moderator',
                            rights: 15
                        };
                    }
                    this.forum.users.push(teacher);
                }
            }
        }

        for (const i in this.roles) {
            if (this.roles[i].selected) {
                if (
                    this.roles[i].structureid.length === 0 &&
                    this.roles[i].shortname === 'nationalAdmin'
                ) {
                    this.roles[i].structureid.push(null);
                }
                for (const j of this.roles[i].structureid) {
                    let role: Role;
                    if (this.roles[i].role === 'reader') {
                        role = {
                            id: this.roles[i].id,
                            role: '',
                            rights: 1,
                            structureid: j
                        };
                    } else if (
                        this.roles[i].role === 'writer' ||
                        this.roles[i].role === undefined
                    ) {
                        role = {
                            id: this.roles[i].id,
                            role: '',
                            rights: 7,
                            structureid: j
                        };
                    } else if (this.roles[i].role === 'moderator') {
                        role = {
                            id: this.roles[i].id,
                            role: 'moderator',
                            rights: 15,
                            structureid: j
                        };
                    }
                    this.forum.roles.push(role);
                }
            }
        }
    }
}
