import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { DialogService } from '../../../services/dialog.service';
import { PermissionService } from '../../../services/permission.service';
import { ThemeService } from '../../../services/theme.service';
import { SubthemeService } from '../../../services/subtheme.service';
import { SubjectService } from '../../../services/subject.service';

import { Forum } from '../../../structures/forum';
import { Theme } from '../../../structures/theme';
import { Subtheme } from '../../../structures/subtheme';
import { ForumSubject } from '../../../structures/subject';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-theme',
    templateUrl: './theme.component.html',
    styleUrls: ['./theme.component.scss']
})
export class ThemeComponent implements OnInit, OnDestroy {
    // createdSubjectInTheme$: Subscription;
    // deletedSubject$: Subscription;
    // movedUpSubtheme$: Subscription;
    // movedDownSubtheme$: Subscription;
    // createdSubtheme$: Subscription;
    // deletedSubtheme$: Subscription;
    // refreshCurrentTheme$: Subscription;
    routeParam$: Subscription;
    private subscriptions = new Subscription();

    theme: Theme;
    forum: Forum;
    themeReady: boolean;

    creatorSelectorOpened: boolean;

    updateTheme: Subject<Theme> = new Subject();

    constructor(
        private dialogService: DialogService,
        private permissionService: PermissionService,
        private themeService: ThemeService,
        private subthemeService: SubthemeService,
        private subjectService: SubjectService,

        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit() {
        this.refreshTheme('');
        this.subscriptions.add(
            this.themeService.refreshCurrentTheme.subscribe((searchTerm: string) => {
                if (this.router.url === '/theme/' + this.theme.id) {
                    this.refreshTheme(searchTerm);
                }
            })
        );

        this.subscriptions.add(
            this.subthemeService.movedUpSubtheme.subscribe((subtheme: Subtheme) => {
                const index = this.theme.subthemes.indexOf(subtheme);
                const firstElement = this.theme.subthemes[index - 1];
                const secondElement = this.theme.subthemes[index];

                this.theme.subthemes.splice(index - 1, 2, secondElement, firstElement);
            })
        );

        this.subscriptions.add(
            this.subthemeService.movedDownSubtheme.subscribe((subtheme: Subtheme) => {
                const index = this.theme.subthemes.indexOf(subtheme);
                const firstElement = this.theme.subthemes[index];
                const secondElement = this.theme.subthemes[index + 1];

                this.theme.subthemes.splice(index, 2, secondElement, firstElement);
            })
        );

        this.subscriptions.add(
            this.subthemeService.deletedSubtheme.subscribe((subthemeId: number) => {
                let index: any;
                for (const i in this.theme.subthemes) {
                    if (this.theme.subthemes[i].id === subthemeId) {
                        index = i;
                    }
                }
                this.theme.subthemes.splice(index, 1);
            })
        );

        this.subscriptions.add(
            this.subthemeService.createdSubtheme.subscribe((subtheme: Subtheme) => {
                this.theme.subthemes.push(subtheme);
            })
        );

        this.subscriptions.add(
            this.subjectService.createdSubjectInTheme.subscribe((subject: ForumSubject) => {
                subject.views = 0;
                this.theme.subjects.push(subject);
            })
        );

        this.subscriptions.add(
            this.subjectService.deletedSubject.subscribe((subjectId: number) => {
                let index: any;
                for (const i in this.theme.subjects) {
                    if (this.theme.subjects[i].id === subjectId) {
                        index = i;
                    }
                }
                this.theme.subjects.splice(index, 1);
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    /**
     * @param {any} data Un objet représentant le theme
     * Initialise le theme
     */
    refreshTheme(searchTerm: string) {
        if (this.routeParam$) {
            this.routeParam$.unsubscribe();
        }
        this.themeReady = false;
        this.routeParam$ = this.route.paramMap
            .pipe(
                switchMap((params: ParamMap) =>
                    this.themeService.getTheme(parseInt(params.get('id'), 10), searchTerm)
                )
            )
            .subscribe((data: any) => {
                this.theme = data;
                this.forum = data.hierarchy.forum;
                this.themeReady = true;
                this.updateTheme.next(this.theme);
            });
        this.subscriptions.add(this.routeParam$);
    }

    isThemeReady(): boolean {
        return this.themeReady;
    }

    getSubthemesCount(): number {
        return this.theme.subthemes.length;
    }

    getPinnedSubjects(): Array<ForumSubject> {
        return this.theme.subjects.filter(function (subject) {
            return subject.pinned;
        });
    }

    getPinnedSubjectsCount(): number {
        return this.theme.subjects.filter(function (subject) {
            return subject.pinned;
        }).length;
    }

    getRegularSubjects(): Array<ForumSubject> {
        return this.theme.subjects.filter(function (subject) {
            return !subject.pinned;
        });
    }

    getRegularSubjectsCount(): number {
        return this.theme.subjects.filter(function (subject) {
            return !subject.pinned;
        }).length;
    }

    canCreateSubject(): Boolean {
        return this.permissionService.canCreateSubjectInTheme(this.forum);
    }

    canCreateSubtheme(): Boolean {
        return this.permissionService.canCreateSubthemeInTheme(this.theme);
    }

    openDialogCreateSubject() {
        this.creatorSelectorOpened = false;
        this.dialogService.openCreateSubjectDialog(this.theme.id, 'theme');
    }

    openDialogCreateSubtheme() {
        this.creatorSelectorOpened = false;
        this.dialogService.openCreateSubthemeDialog(this.theme.id);
    }

    openCreatorSelector(): void {
        this.creatorSelectorOpened = !this.creatorSelectorOpened;
    }

    closeCreatorSelector(): void {
        this.creatorSelectorOpened = false;
    }

    isCreatorSelectorOpened(): boolean {
        return this.creatorSelectorOpened;
    }

    getTooltipText(): string {
        if (this.canCreateSubtheme()) {
            return 'Créer un nouveau sous-thème/sujet';
        } else {
            return 'Créer un nouveau sujet';
        }
    }
}
