<div class="theme-container" *ngIf="isThemeReady()">
    <div class="scrollbar-container">
        <div class="subjects-container" *ngIf="getPinnedSubjectsCount() > 0">
            <div class="header purple">
                <div class="title">
                    <b>{{ getPinnedSubjectsCount() }}</b> sujet(s) épinglés
                </div>
                <div class="message-count">Messages</div>
                <div class="view-count">Vues</div>
                <div class="last-message">Dernier message</div>
                <div class="blank"></div>
            </div>
            <app-subject-entry
                *ngFor="let subject of getPinnedSubjects()"
                [subject]="subject"
                [forum]="forum"
                [ngClass]="{ bold: !subject.viewed }"
            ></app-subject-entry>
        </div>
        <div class="separator" *ngIf="getPinnedSubjectsCount() > 0"></div>
        <div class="subtheme-container">
            <div class="header purple">
                <div class="title">
                    <b>{{ getSubthemesCount() }}</b> sous-thèmes
                </div>
                <div class="blank first"></div>
                <div class="message-count">Messages</div>
                <div class="blank second"></div>
                <div class="last-message">Dernier message</div>
                <div class="blank third"></div>
            </div>
            <app-subtheme-entry
                *ngFor="let subtheme of theme.subthemes"
                [theme]="theme"
                [subtheme]="subtheme"
                [forum]="forum"
                [inbox]="false"
                [ngClass]="{ bold: !subtheme.viewed }"
            ></app-subtheme-entry>
        </div>
        <div class="separator"></div>
        <div class="subjects-container">
            <div class="header">
                <div class="title">
                    <b>{{ getRegularSubjectsCount() }}</b> sujet(s)
                </div>
                <div class="message-count">Messages</div>
                <div class="view-count">Vues</div>
                <div class="last-message">Dernier message</div>
                <div class="blank"></div>
            </div>
            <app-subject-entry
                *ngFor="let subject of getRegularSubjects()"
                [subject]="subject"
                [forum]="forum"
                [ngClass]="{ bold: !subject.viewed }"
            ></app-subject-entry>
        </div>
    </div>
    <div class="content-selection" *ngIf="isCreatorSelectorOpened()">
        <div class="entry" (click)="openDialogCreateSubtheme()" *ngIf="canCreateSubtheme()">
            <div class="icon-Repertoire-plein"></div>
            <div class="label">Créer un sous-thème</div>
        </div>
        <div class="entry" (click)="openDialogCreateSubject()" *ngIf="canCreateSubject()">
            <div class="icon-Messages-cercle"></div>
            <div class="label">Créer un sujet</div>
        </div>
    </div>
    <div
        class="add-button"
        *ngIf="canCreateSubtheme() || canCreateSubject()"
        (click)="openCreatorSelector()"
        [tooltip]="getTooltipText()"
        tooltipColor="#955099"
    >
        <span class="icon-plus"></span>
    </div>
</div>
<div class="loadingEntry" *ngIf="!isThemeReady()">
    <div class="point1 point"></div>
    <div class="point2 point"></div>
    <div class="point3 point"></div>
</div>
