<div
    class="container"
    [ngClass]="{ purple: isPinnedSubject(), bold: !isViewedSubject() }"
    routerLink="/subject/{{ subject.id }}"
>
    <span class="icon-Messages-cercle" *ngIf="!isPinnedSubject()"></span>
    <span class="icon-epingler-cercle" *ngIf="isPinnedSubject()"></span>
    <div class="title">
        <span class="name" *ngIf="!editing">{{ subject.title }}</span>
        <div *ngIf="editing">
            <input
                #inputTitle
                class="input-title"
                [(ngModel)]="subject.title"
                (keyup.enter)="renameSubject($event)"
                (keyup.escape)="cancelEditSubject()"
                (click)="$event.stopPropagation()"
            />
            <span class="icon-Envoyer" (click)="renameSubject($event)"></span>
        </div>
    </div>
    <div class="response-count">
        <span>{{ getMessageCount() }}</span>
    </div>
    <div class="view-count">
        <span>{{ getViewCount() }}</span>
    </div>
    <div class="last-message-author" *ngIf="subject.last">
        <span class="icon" [ngClass]="getRoleIcon()"></span>
        <span class="name">{{ getSubjectLastMessageAuthor() }}</span>
    </div>
    <div class="last-message-date" [ngClass]="{ toggle: showIcons() }">
        {{ getSubjectLastMessageDate() | date: 'dd/MM/yyyy' }}
        {{ getSubjectLastMessageDate() | date: 'HH:mm' }}
    </div>
    <div class="icons">
        <span
            class="icon-epingler"
            *ngIf="canPinSubject() && !isPinnedSubject()"
            (click)="pinSubject($event)"
            tooltip="Epingler le sujet"
            tooltipPosition="left"
            tooltipColor="#955099"
        ></span>
        <span
            class="icon-desepingler"
            *ngIf="canPinSubject() && isPinnedSubject()"
            (click)="pinSubject($event)"
            tooltip="Désépingler le sujet"
            tooltipPosition="left"
            tooltipColor="#955099"
        ></span>
        <span
            class="icon-Repertoire"
            *ngIf="canMoveSubject()"
            (click)="moveSubject($event)"
            tooltip="Déplacer le sujet"
            tooltipPosition="left"
            tooltipColor="#955099"
        ></span>
        <span
            class="icon-Editer"
            *ngIf="canEditSubject()"
            (click)="editSubject($event)"
            tooltip="Renommer le sujet"
            tooltipPosition="left"
            tooltipColor="#955099"
        ></span>
        <span
            class="icon-bin"
            *ngIf="canDeleteSubject()"
            (click)="deleteSubject($event)"
            tooltip="Supprimer le sujet"
            tooltipPosition="left"
            tooltipColor="#955099"
        ></span>
    </div>
</div>
