import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Forum } from '../structures/forum';
import { FlashMessageService } from './flash-message.service';
import { log } from './decorators/log.decorator';

/**
 * Service gérant tous les appels aux webservice liés aux forum
 */
@Injectable({
    providedIn: 'root'
})
export class ForumService {
    refreshAllForums: Subject<void> = new Subject();
    refreshCurrentForum: ReplaySubject<string> = new ReplaySubject(1);
    maskedForum: Subject<Forum> = new Subject();
    unmaskedForum: Subject<Forum> = new Subject();
    unarchivedForum: Subject<Forum> = new Subject();
    disabledForum: Subject<number> = new Subject();
    deletedForum: Subject<number> = new Subject();
    ForumsSubject: BehaviorSubject<Array<Forum>> = new BehaviorSubject([]);
    searchTerm$: BehaviorSubject<string> = new BehaviorSubject('');

    constructor(private http: HttpClient, private flashMessageService: FlashMessageService) {}

    /**
     * @param {number} id L'identifiant du forum à récuperer
     * @param {string} searchTerm Une chaîne de caractère permettant de filtrer les themes et sujets à l'intérieur du forum
     * Récupère les informations d'un forum spécifique
     * @returns {Observable} Un observable du forum récupéré
     */
    @log() getForum(id: number, searchTerm?: string, withSubthemes?: boolean): Observable<any> {
        const params: any = {};

        if (searchTerm !== undefined) {
            params.search = searchTerm;
        }

        if (withSubthemes !== undefined) {
            params.withSubthemes = withSubthemes;
        }

        return this.http.get('/forums/' + id, { params });
    }

    /**
     * Récupère la liste des forums accessibles par l'utilisateur actuel
     * @returns {Observable} Un observable de la liste des forums de l'utilisateur courant
     */
    @log() getForums(search: string): Observable<any> {
        return this.http.get('/forums', { params: { search } }).pipe(
            tap({
                next: (data: any) => {
                    this.ForumsSubject.next(data);
                }
            })
        );
    }

    /**
     * retourne la liste des forums de l'utilisateur connecté sous forme d'observable
     * @returns {Observable} Observable d'un array de forums
     */
    getForumsSubject(): Observable<any> {
        return this.ForumsSubject.asObservable();
    }

    /**
     * @param {any} params Un objet contenant 2 paramètres : structureid et search
     * Récupère la liste des forums auxquels l'utilisateur peut s'inscrire
     * @returns {Observable} Un observable de la liste des forums correspondant aux paramètres
     */
    @log() searchPublicForums(params: any): Observable<any> {
        return this.http.get('/forums/public', { params });
    }

    /**
     * @param {any} params Un objet contenant 2 paramètres : structureid et search
     * Récupère la liste des forums auxquels l'utilisateur peut s'inscrire
     * @returns {Observable} Un observable de la liste des forums correspondant aux paramètres
     */
    @log() getPublicForumsCount(params: any): Observable<any> {
        return this.http.get('/forums/public?count=true', { params });
    }

    /**
     * @param {any} params Un objet contenant 2 paramètres : type et id
     * Récupère la liste des forums partagés par l'utilisateur actuel et les élements passés en paramètres
     * @returns {Observable} Un observable de la liste des forums partagés
     */
    @log() getSharedForums(params: any): Observable<any> {
        return this.http.get('/forums/shared', { params });
    }

    /**
     * @param {any} body Un object contenant 1 paramètre : Un tableau regroupant la liste des id des forums auxquels on souhaite s'inscrire
     * Inscrit l'administrateur courant à un ou plusieurs forums
     * @returns {Observable} Un observable d'un booléen si l'inscription aux forums s'est déroulé correctement
     */
    @log() subscribeForums(body: any): Observable<any> {
        return this.http.post('/forums/register', body);
    }

    /**
     * @param {any} body Un object contenant 1 paramètre : Un objet représentant le forum à créer
     * Crée un forum
     * @returns {Observable} Un observable d'un booléen si la création du forum s'est déroulé correctement
     */
    @log() createForum(body: any): Observable<any> {
        return this.http.post('/forums', body).pipe(
            tap({
                next: () => {
                    this.flashMessageService.flash(`Le forum a bien été créé`);
                }
            })
        );
    }

    /**
     * @param {number} forumId L'identifiant du forum que l'on souhaite mettre à jour
     * @param {any} body Un object contenant 1 paramètre : Un objet représentant un forum existant
     * Met à jour un forum
     * @returns {Observable} Un observable d'un booléen si la mise à jour du forum s'est déroulé correctement
     */
    @log() updateForum(forumId: number, body: any): Observable<any> {
        return this.http.put('/forums/' + forumId, body).pipe(
            tap({
                next: () => {
                    this.flashMessageService.flash(`Le forum a bien été mis à jour`);
                }
            })
        );
    }

    /**
     * @param {number} id L'identifiant du forum à désactiver
     * Désactive un forum
     * @returns {Observable} Un observable d'un booléen indiquant si la désactivation s'est déroulé correctement
     */
    @log() disableForum(id: number): Observable<any> {
        return this.http.put('/forums/' + id + '/archive/1', {}).pipe(
            tap({
                next: () => {
                    this.flashMessageService.flash(`Le forum a bien été désactivé`);
                }
            })
        );
    }

    /**
     * @param {number} id L'identifiant du forum à réactiver
     * Réactive un forum
     * @returns {Observable} Un observable d'un booléen indiquant si la réactivation s'est déroulé correctement
     */
    @log() enableForum(id: number): Observable<any> {
        return this.http.put('/forums/' + id + '/archive/0', {}).pipe(
            tap({
                next: () => {
                    this.flashMessageService.flash(`Le forum a bien été activé`);
                }
            })
        );
    }

    /**
     * @param {number} id L'identifiant du forum à supprimer
     * Supprime un forum
     * @returns {Observable} Un observable d'un booléen indiquant si la suppression s'est déroulé correctement
     */
    @log() deleteForum(id: number): Observable<any> {
        return this.http.delete('/forums/' + id).pipe(
            tap({
                next: () => {
                    this.flashMessageService.flash(`Le forum a bien été supprimé`);
                }
            })
        );
    }

    /**
     * @param {number} id L'identifiant du forum à masquer
     * Masque un forum pour l'utilisateur actuel
     * @returns {Observable} Un observable d'un booléen indiquant si le masquage s'est déroulé correctement
     */
    @log() maskForum(id: number): Observable<any> {
        return this.http.post('/forums/setVisibility/' + id, { visibility: false });
    }

    /**
     * @param {number} id L'identifiant du forum à démasquer
     * Démasque un forum pour l'utilisateur actuel
     * @returns {Observable} Un observable d'un booléen indiquant si le démasquage s'est déroulé correctement
     */
    @log() unmaskForum(id: number): Observable<any> {
        return this.http.post('/forums/setVisibility/' + id, { visibility: true });
    }

    @log() toggleMuteForum(forum: Forum): Observable<any> {
        return this.http.get(`/forums/${forum.id}/${forum.isMuted ? 'unmute' : 'mute'}`);
    }

    setSearchTerm(searchTerm: string): void {
        this.searchTerm$.next(searchTerm);
    }

    getSearchTerm(): Observable<string> {
        return this.searchTerm$.asObservable();
    }

    /**
     * Un event récupérable par les composants permettant de mettre à jour la liste des forums
     */
    emitRefreshForums() {
        this.refreshAllForums.next();
    }

    /**
     * Un event récupérable par les composants permettant de mettre à jour le forum actuel
     */
    emitRefreshForum(searchTerm?: string) {
        this.refreshCurrentForum.next(searchTerm);
    }

    emitMaskedForum(forum: Forum) {
        this.maskedForum.next(forum);
    }

    emitUnmaskedForum(forum: Forum) {
        this.unmaskedForum.next(forum);
    }

    emitUnarchivedForum(forum: Forum) {
        this.unarchivedForum.next(forum);
    }

    emitDisabledForum(forumId: number) {
        this.disabledForum.next(forumId);
    }

    emitDeletedForum(forumId: number) {
        this.deletedForum.next(forumId);
    }
}
