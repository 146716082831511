<div class="header">
    <span class="label">Masquer un forum</span>
    <span class="icon-close" (click)="closeDialog()"></span>
</div>
<mat-dialog-content class="content">
    <div class="icon-container">
        <span class="icon-masquer"></span>
    </div>
    <div class="message-container">
        En masquant ce forum, vous ne serez plus notifié de son activité.<br />
        Vous pouvez à tout moment rétablir ces notifications en allant le récupérer dans « Eléments
        masqués »
    </div>
</mat-dialog-content>
<div class="footer">
    <div class="mask-button" (click)="maskForum()">J'ai compris</div>
</div>
