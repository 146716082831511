<div class="header">
    <span class="icon-easi-forum-line"></span>
    <span class="label">Nouveau forum</span>
    <span class="icon-close" (click)="closeDialog()"></span>
</div>
<div class="content">
    <mat-dialog-content>
        <p>
            Les <strong>forums publics</strong> sont
            <strong>accessibles à toute personne</strong> ayant accès aux forums de votre structure.
        </p>
        <p>
            Les <strong>forums privés</strong> ne sont accessibles
            <strong>qu'aux personnes de votre choix</strong>. Vous pouvez de plus distinguer des
            utilisateurs ne pouvant que consulter le forum, de ceux qui pourront contribuer
        </p>
        <p>Attention, <strong>le choix du type de forum est irréversible</strong>.</p>
    </mat-dialog-content>
</div>
<div class="footer">
    <button class="button-create" (click)="selectForum(true)">
        Créer un forum <strong>public</strong>
    </button>
    <button class="button-create last" (click)="selectForum(false)">
        Créer un forum <strong>privé</strong>
    </button>
</div>
