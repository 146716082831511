import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';

import { CreateForumComponent } from '../dialogs/create-forum/create-forum.component';
import { SelectForumComponent } from '../dialogs/select-forum/select-forum.component';
import { CreateThemeComponent } from '../dialogs/create-theme/create-theme.component';
import { CreateSubthemeComponent } from '../dialogs/create-subtheme/create-subtheme.component';
import { CreateSubjectComponent } from '../dialogs/create-subject/create-subject.component';
import { SubscribeForumComponent } from '../dialogs/subscribe-forum/subscribe-forum.component';
import { MembersComponent } from '../dialogs/members/members.component';
import { UploadFilesComponent } from '../dialogs/upload-files/upload-files.component';
import { WarningComponent } from '../dialogs/warning/warning.component';
import { SharedForumComponent } from '../dialogs/shared-forum/shared-forum.component';
import { ErrorComponent } from '../dialogs/error/error.component';
import { PreviewImageComponent } from '../dialogs/preview-image/preview-image.component';
import { MaskForumComponent } from '../dialogs/mask-forum/mask-forum.component';
import { MoveSubjectComponent } from '../dialogs/move-subject/move-subject.component';

import { Forum } from '../structures/forum';
import { ForumSubject } from '../structures/subject';
import { LoginService } from './login.service';

/**
 * Service gérant toutes les ouvertures de dialog dans easi-forum
 */
@Injectable({
    providedIn: 'root'
})
export class DialogService {
    constructor(private dialog: MatDialog, private loginService: LoginService) {
        this.subscriptions.add(
            this.loginService.closeAllDialogs$.subscribe((data: boolean) => {
                if (data) {
                    this.closeAllDialogs();
                }
            })
        );
    }

    private subscriptions: Subscription = new Subscription();

    /**
     * @param {boolean} type Le type du forum : true = public, false = private
     * @param {number} forumId L'identifiant optionnel du forum que l'utilisateur souhaite modifier
     * @param {number} groupId Les identifiants optionnels des groupes que l'utilisateur souhaite ajouter au forum
     * @param {number} userId Les identifiants optionnels des utilisateurs que l'utilisateur souhaite ajouter au forum
     * Ouvre la fenetre de création de forum
     */
    openCreateForumDialog(type: boolean, forumId?: number, groupId?: number, userId?: number) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '650px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {};
        dialogConfig.id = 'create-forum';
        dialogConfig.data.type = type;
        if (forumId) {
            dialogConfig.data.forumId = forumId;
        }
        if (userId) {
            dialogConfig.data.userId = userId;
        }
        if (groupId) {
            dialogConfig.data.groupId = groupId;
        }

        const ref: MatDialogRef<CreateForumComponent> = this.dialog.open(
            CreateForumComponent,
            dialogConfig
        );
        ref.componentInstance.openErrorDialog.subscribe((msg: string) => {
            this.openErrorDialog(msg);
        });
    }

    /**
     * Ouvre la fenetre de sélection du type de forum
     */
    openSelectForumDialog() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';

        const ref: MatDialogRef<SelectForumComponent> = this.dialog.open(
            SelectForumComponent,
            dialogConfig
        );
        ref.componentInstance.openCreateForumDialog.subscribe((type: boolean) => {
            this.openCreateForumDialog(type);
        });
    }

    /**
     * @param {number} instanceid L'id de l'élement où l'on souhaite créer le sujet
     * @param {string} type Le type d'élement où l'on souhaite créer le sujet : forum/theme/subtheme
     * Ouvre la fenetre de création de sujet
     */
    openCreateSubjectDialog(instanceid: number, type: string) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {};
        dialogConfig.data = {
            instanceid: instanceid,
            type: type
        };

        const ref: MatDialogRef<CreateSubjectComponent> = this.dialog.open(
            CreateSubjectComponent,
            dialogConfig
        );
        ref.componentInstance.openErrorDialog.subscribe((msg: string) => {
            this.openErrorDialog(msg);
        });
    }

    /**
     * @param {number} forumId L'id du forum où l'on souhaite créer un thème
     * Ouvre la fenetre de création de thème
     */
    openCreateThemeDialog(forumId: number) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {};
        dialogConfig.data = {
            instanceid: forumId
        };

        const ref: MatDialogRef<CreateThemeComponent> = this.dialog.open(
            CreateThemeComponent,
            dialogConfig
        );
        ref.componentInstance.openErrorDialog.subscribe((msg: string) => {
            this.openErrorDialog(msg);
        });
    }

    /**
     * @param {number} themeId L'identifiant du theme où l'on souhaite créer un sous-thème
     * Ouvre la fenetre de création de sous-thème
     */
    openCreateSubthemeDialog(themeId: number) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {};
        dialogConfig.data = {
            instanceid: themeId
        };

        const ref: MatDialogRef<CreateSubthemeComponent> = this.dialog.open(
            CreateSubthemeComponent,
            dialogConfig
        );
        ref.componentInstance.openErrorDialog.subscribe((msg: string) => {
            this.openErrorDialog(msg);
        });
    }

    /**
     * @param {number} forumId Les identifiants optionnels des forums auxquels l'utilisateur souhaite s'enregistrer
     * Ouvre la fenetre d'enregistrement à un forum
     */
    openSubscribeForumDialog(forumId?: number) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '900px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.id = 'subscribe-forum';
        dialogConfig.data = {};
        if (forumId) {
            dialogConfig.data.forumId = forumId;
        }

        const ref: MatDialogRef<SubscribeForumComponent> = this.dialog.open(
            SubscribeForumComponent,
            dialogConfig
        );
        ref.componentInstance.openErrorDialog.subscribe((msg: string) => {
            this.openErrorDialog(msg);
        });
    }

    /**
     * @param {number} forumId L'id du forum dont on souhaite afficher les membres
     * Ouvre la fenetre des membres du forum
     */
    openMembersDialog(forumId: number) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {};
        dialogConfig.data.forumId = forumId;

        const ref: MatDialogRef<MembersComponent> = this.dialog.open(
            MembersComponent,
            dialogConfig
        );
        ref.componentInstance.openErrorDialog.subscribe((msg: string) => {
            this.openErrorDialog(msg);
        });
    }

    /**
     * Ouvre la fenetre de sélection de pièce-jointe d'un post
     * @returns { Observable<Array<File> } Un observable du tableau contenant les pièces-jointes à joindre au post
     */
    openUploadFilesDialog(): Observable<Array<File>> {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';

        const ref: MatDialogRef<UploadFilesComponent> = this.dialog.open(
            UploadFilesComponent,
            dialogConfig
        );
        return new Observable((observer) => {
            ref.afterClosed().subscribe((result: Array<File>) => {
                observer.next(result);
                observer.complete();
            });
        });
    }

    /**
     * @param { string } warning L'action que l'on souhaite effectuer une fois la confirmation de l'utilisateur obtenu
     * @param { number } warningId L'id de l'élement sur lequel on souhaite agir
     * Ouvre une fenetre d'avertissement/confirmation avant d'effectuer une action
     */
    openWarningDialog(warning: string, warningId?: number) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {};
        dialogConfig.data.warning = warning;
        if (warningId) {
            dialogConfig.data.warningId = warningId;
        }

        const ref: MatDialogRef<WarningComponent> = this.dialog.open(
            WarningComponent,
            dialogConfig
        );
        ref.componentInstance.openErrorDialog.subscribe((msg: string) => {
            this.openErrorDialog(msg);
        });
    }

    /**
     * @param { string } type Le type dont on souhaite récuperer les forums partagés : 'group' ou 'user'
     * @param { number } id L'id d'un groupe ou d'un utilisateur
     * Ouvre la fenêtre listant les forums partagés avec un utilisateur ou un groupe
     */
    openSharedForumDialog(type: string, id: number): Observable<Forum | undefined> {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {};
        if (type) {
            dialogConfig.data.type = type;
        }
        if (id) {
            dialogConfig.data.id = id;
        }

        const ref: MatDialogRef<SharedForumComponent> = this.dialog.open(
            SharedForumComponent,
            dialogConfig
        );
        ref.componentInstance.openErrorDialog.subscribe((msg: string) => {
            this.openErrorDialog(msg);
        });
        return ref.afterClosed();
    }

    /**
     * @param {string} content Le message d'erreur que l'on souhaite afficher
     * Ouvre la fenetre d'erreur
     */
    openErrorDialog(content?: String) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        if (content) {
            dialogConfig.data = content;
        }

        this.dialog.open(ErrorComponent, dialogConfig);
    }

    /**
     * @param {string} url L'URL d'une image
     * Ouvre la fenetre permettant de prévisualiser une image
     */
    openPreviewImage(url: string) {
        this.dialog.open(PreviewImageComponent, {
            data: { url: url }
        });
    }

    /**
     * @param { number } forumId L'id d'un forum
     * Ouvre la fenetre permettant de masquer un forum
     */
    openMaskForumDialog(forum: Forum) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.width = '400px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {};
        if (forum) {
            dialogConfig.data.forum = forum;
        }

        const ref: MatDialogRef<MaskForumComponent> = this.dialog.open(
            MaskForumComponent,
            dialogConfig
        );
        ref.componentInstance.openErrorDialog.subscribe((msg: string) => {
            this.openErrorDialog(msg);
        });
    }

    /**
     * @param {ForumSubject} subject Le sujet que l'on souhaite déplacer
     * @param {number} forumId L'id du forum contenant le sujet que l'on souhaite déplacer
     * Ouvre la fenetre permettant de déplacer un sujet à l'intérieur d'un forum
     */
    openMoveSubjectDialog(subject: ForumSubject, forumId: number) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {};
        dialogConfig.data = {
            subject: subject,
            forumId: forumId
        };

        const ref: MatDialogRef<MoveSubjectComponent> = this.dialog.open(
            MoveSubjectComponent,
            dialogConfig
        );
        ref.componentInstance.openErrorDialog.subscribe((msg: string) => {
            this.openErrorDialog(msg);
        });
    }

    /**
     * ferme toutes les modales ouvertes (utilisé par is_still_connected)
     */
    closeAllDialogs(): void {
        this.dialog.closeAll();
    }
}
