import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { ForumSubject } from '../structures/subject';
import { log } from './decorators/log.decorator';

/**
 * Service gérant tous les appels aux webservice liés aux sujets
 */
@Injectable({
    providedIn: 'root'
})
export class SubjectService {
    constructor(private http: HttpClient) {}

    refreshCurrentSubject: Subject<string> = new Subject();
    createdSubjectInForum: Subject<ForumSubject> = new Subject();
    createdSubjectInTheme: Subject<ForumSubject> = new Subject();
    createdSubjectInSubtheme: Subject<ForumSubject> = new Subject();
    deletedSubject: Subject<number> = new Subject();

    /**
     * @param {any} subject Un objet contenant 2 paramètres : L'id du forum dans lequel on souhaite créer le sujet, et le nom du sujet
     * Crée un sujet
     * @returns {Observable} Un observable d'un booléen indiquant si la création du sujet s'est déroulé correctement
     */
    @log() createSubject(body: any): Observable<any> {
        return this.http.post('/subjects', body);
    }

    /**
     * @param {any} subject Un objet contenant 2 paramètres : L'id du forum dans lequel on souhaite créer le sujet, et le nom du sujet
     * Crée un sujet
     * @returns {Observable} Un observable d'un booléen indiquant si la création du sujet s'est déroulé correctement
     */
    @log() updateSubject(body: any): Observable<any> {
        return this.http.put('/subjects/' + body.id, body);
    }

    /**
     * @param {number} id L'identifiant du sujet à récuperer
     * Récupère les informations d'un sujet spécifique
     * @returns {Observable} Un observable du sujet récupéré
     */
    @log() getSubject(id: number, searchTerm?: string): Observable<any> {
        const params: any = {};

        if (searchTerm) {
            params.search = searchTerm;
        }

        return this.http.get('/subjects/' + id, { params });
    }

    /**
     * @param {number} id L'identifiant du sujet à supprimer
     * Supprime les informations d'un sujet spécifique
     * @returns {Observable} Vrai si l'outil
     */
    @log() deleteSubject(id: number): Observable<any> {
        return this.http.delete('/subjects/' + id);
    }

    /**
     * Un event récupérable par les composants permettant de mettre à jour la liste des sujets
     */
    refreshSubject(searchTerm?: string) {
        this.refreshCurrentSubject.next(searchTerm);
    }

    emitCreatedSubjectInForum(subject: ForumSubject) {
        this.createdSubjectInForum.next(subject);
    }

    emitCreatedSubjectInTheme(subject: ForumSubject) {
        this.createdSubjectInTheme.next(subject);
    }

    emitCreatedSubjectInSubtheme(subject: ForumSubject) {
        this.createdSubjectInSubtheme.next(subject);
    }

    emitDeletedSubject(subjectId: number) {
        this.deletedSubject.next(subjectId);
    }
}
