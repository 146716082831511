import { Component, Input, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { DialogService } from '../../../services/dialog.service';
import { ForumService } from '../../../services/forum.service';
import { PermissionService } from '../../../services/permission.service';

import { Forum } from '../../../structures/forum';
import { Subscription } from 'rxjs';
import { FlashMessageService } from 'src/app/services/flash-message.service';

/**
 * Composant gérant l'affichage des forums dans le menu de gauche
 */
@Component({
    selector: 'app-nav-entry',
    templateUrl: './nav-entry.component.html',
    styleUrls: ['./nav-entry.component.scss']
})
export class NavEntryComponent implements OnDestroy {
    @Input() forum: any;
    @Input() selectedForum: number;
    @Input() type: string;

    lengthForum: string;

    private subscriptions = new Subscription();

    constructor(
        private dialogService: DialogService,
        private forumService: ForumService,
        private permissionService: PermissionService,
        private flashMessageService: FlashMessageService
    ) {}

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    /**
     * Indique si le forum actuel est sélectionné ou non
     * @returns {Boolean} True si le forum actuel est sélectionné, faux dans le cas contraire
     */
    isForumSelected(): Boolean {
        return this.forum.id === this.selectedForum;
    }

    canEditForum(): Boolean {
        return this.permissionService.canEditForum(this.forum, this.type);
    }

    canDisableForum(): Boolean {
        return this.permissionService.canDisableForum(this.forum, this.type);
    }

    canMaskForum(): Boolean {
        return this.permissionService.canMaskForum(this.type);
    }

    canEnableForum(): Boolean {
        return this.permissionService.canEnableForum(this.forum, this.type);
    }

    canDeleteForum(): Boolean {
        return this.permissionService.canDeleteForum(this.forum, this.type);
    }

    isForumMasked(): Boolean {
        return this.forum.hidden !== false;
    }

    getForumNotificationsIcon(): string {
        if (this.forum.isMuted) {
            return 'icon-notifications';
        }
        return 'icon-notifications-barree';
    }

    getNotificationsIconTooltip(): string {
        if (this.forum.isMuted) {
            return 'activer les notifications';
        }
        return 'désactiver les notifications';
    }

    toggleForumNotifications($event: Event, forum: Forum): void {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.forumService.toggleMuteForum(forum).subscribe((data) => {
                this.forum.isMuted = !this.forum.isMuted;
                this.flashMessageService.flash(
                    `les notifications du forum ${forum.name} ont été ${
                        this.forum.isMuted ? 'désactivées' : 'activées'
                    }`
                );
            })
        );
    }

    /**
     * @param {Event} $event L'event Javascript émis lors du clic
     * Ouvre la fenêtre d'édition du forum
     */
    openEditForumDialog($event: Event) {
        $event.stopPropagation();
        this.dialogService.openCreateForumDialog(this.forum.public, this.forum.id);
    }

    /**
     * @param {Event} $event L'event Javascript émis lors du clic
     * Ouvre la fenêtre des membres du forum
     */
    openMembersDialog($event: Event) {
        $event.stopPropagation();
        this.dialogService.openMembersDialog(this.forum.id);
    }

    /**
     * @param {Event} $event L'event Javascript émis lors du clic
     * Ouvre la fenêtre permettant de désactiver le forum actuel
     */
    openDisableForumDialog($event: Event) {
        $event.stopPropagation();
        this.dialogService.openWarningDialog('DISABLE_FORUM', this.forum.id);
    }

    /**
     * @param {Event} $event L'event Javascript émis lors du clic
     * Ouvre la fenêtre permettant de supprimer le forum actuel
     */
    openDeleteForumDialog($event: Event) {
        $event.stopPropagation();
        this.dialogService.openWarningDialog('DELETE_FORUM', this.forum.id);
    }

    /**
     * Ouvre la fenêtre permettant de masquer uin forum
     * @param {Event} $event L'évenement Javascript émis lors du clic
     */
    openMaskForumDialog($event: Event) {
        $event.stopPropagation();
        this.dialogService.openMaskForumDialog(this.forum);
    }

    unmaskForum($event: Event) {
        $event.stopPropagation();
        this.subscriptions.add(
            this.forumService.unmaskForum(this.forum.id).subscribe(
                (data: any) => {
                    this.forumService.emitUnmaskedForum(this.forum);
                },
                (error: HttpErrorResponse) => {
                    this.dialogService.openErrorDialog(error.error.userMessage);
                }
            )
        );
    }

    unarchiveForum($event: Event) {
        $event.stopPropagation();
        this.subscriptions.add(
            this.forumService.enableForum(this.forum.id).subscribe(
                () => {
                    this.forumService.emitUnarchivedForum(this.forum);
                },
                (error: HttpErrorResponse) => {
                    this.dialogService.openErrorDialog(error.error.userMessage);
                }
            )
        );
    }
}
