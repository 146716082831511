<div
    class="subtheme-container"
    routerLink="/subtheme/{{ subtheme.id }}"
    [ngClass]="{ inbox: isInbox(), bold: !isViewedSubtheme() }"
>
    <span class="icon-Repertoire-plein"></span>
    <div class="title">
        <span class="name" *ngIf="!editing">{{ subtheme.title }}</span>
        <div *ngIf="editing">
            <input
                #inputTitle
                class="input-title"
                [(ngModel)]="subtheme.title"
                (keyup.enter)="renameSubtheme($event)"
                (keyup.escape)="cancelEditSubtheme()"
                (click)="$event.stopPropagation()"
            />
            <span class="icon-Envoyer" (click)="renameSubtheme($event)"></span>
        </div>
    </div>
    <div class="posts-count">
        <span>{{ getPostCount() }}</span>
    </div>
    <div class="blank"></div>
    <div class="last-message-author" *ngIf="subtheme.last">
        <span class="icon" [ngClass]="getRoleIcon()"></span>
        <span class="name">{{ getSubthemeLastMessageAuthor() }}</span>
    </div>
    <div class="last-message-date" [ngClass]="{ toggle: showIcons() }">
        {{ getSubthemeLastMessageDate() | date: 'dd/MM/yyyy' }}
        {{ getSubthemeLastMessageDate() | date: 'HH:mm' }}
    </div>
    <div class="icons">
        <span
            class="icon-Monter"
            *ngIf="canMoveSubtheme()"
            [ngClass]="{ disabled: !canMoveUpSubtheme() }"
            (click)="moveUpSubtheme($event)"
            tooltip="Remonter"
            tooltipPosition="left"
            tooltipTextColor="#955099"
            tooltipColor="#fff"
        ></span>
        <span
            class="icon-Descendre"
            *ngIf="canMoveSubtheme()"
            [ngClass]="{ disabled: !canMoveDownSubtheme() }"
            (click)="moveDownSubtheme($event)"
            tooltip="Descendre"
            tooltipPosition="left"
            tooltipTextColor="#955099"
            tooltipColor="#fff"
        ></span>
        <span
            class="icon-Editer"
            *ngIf="canEditSubtheme()"
            (click)="editSubtheme($event)"
            tooltip="Renommer le sous-thème"
            tooltipPosition="left"
            tooltipTextColor="#955099"
            tooltipColor="#fff"
        ></span>
        <span
            class="icon-bin"
            *ngIf="canDeleteSubtheme()"
            (click)="deleteSubtheme($event)"
            tooltip="Supprimer le sous-thème"
            tooltipPosition="left"
            tooltipTextColor="#955099"
            tooltipColor="#fff"
        ></span>
    </div>
</div>
