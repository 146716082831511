<div class="container" [ngClass]="{ selected: isForumSelected() }">
    <div class="icon-easi-forum-line"></div>
    <div class="title">{{ forum.name }}</div>
    <div class="type">
        <span class="public" *ngIf="forum.public">Public</span>
        <span
            class="private"
            *ngIf="!forum.public"
            tooltip="Voir les utilisateurs"
            tooltipPosition="left"
            tooltipColor="#955099"
            (click)="openMembersDialog($event)"
            >({{ forum.membersCount }})</span
        >
    </div>
    <div class="icons">
        <span
            [ngClass]="getForumNotificationsIcon()"
            [tooltip]="getNotificationsIconTooltip()"
            tooltipPosition="left"
            tooltipColor="#955099"
            (click)="toggleForumNotifications($event, forum)"
        ></span>
        <span
            class="icon-Editer"
            *ngIf="canEditForum()"
            tooltip="Modifier le forum"
            tooltipPosition="left"
            tooltipColor="#955099"
            (click)="openEditForumDialog($event)"
        ></span>
        <span
            class="icon-archiver"
            *ngIf="canDisableForum()"
            tooltip="Désactiver le forum"
            tooltipPosition="left"
            tooltipColor="#955099"
            (click)="openDisableForumDialog($event)"
        ></span>
        <span
            class="icon-masquer"
            *ngIf="canMaskForum() && !isForumMasked() && type === 'member'"
            tooltip="Masquer l'espace"
            tooltipPosition="left"
            tooltipColor="#955099"
            (click)="openMaskForumDialog($event)"
        ></span>
        <span
            class="icon-voir"
            *ngIf="canMaskForum() && isForumMasked()"
            tooltip="Réafficher l'espace"
            tooltipPosition="left"
            tooltipColor="#955099"
            (click)="unmaskForum($event)"
        ></span>
        <span
            class="icon-Nouvelleversioncontenu"
            *ngIf="canEnableForum()"
            tooltip="Réactiver le forum"
            tooltipPosition="left"
            tooltipColor="#955099"
            (click)="unarchiveForum($event)"
        ></span>
        <span
            class="icon-bin"
            *ngIf="canDeleteForum()"
            tooltip="Supprimer le forum"
            tooltipPosition="left"
            tooltipColor="#955099"
            (click)="openDeleteForumDialog($event)"
        ></span>
    </div>
</div>
