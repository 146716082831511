<div class="row align-items-center" id="carousel-container">
    <div class="col-2" id="arrow-left">
        <!-- <span class="icon-prev" (click)="setPreviousPicture()"></span> -->
    </div>
    <div class="col-8" id="carousel-pictures-container">
        <div id="carousel-picture-description">
            <p [innerHTML]="picDescription"></p>
        </div>
        <img
            id="carousel-current-picture"
            alt="Bienvenue dans easi forum"
            [class.show]="showingAnimation"
            [src]="picURL"
        />
        <!-- <div id="carousel-pictures-indicators">
			<span class="picture-indicator" *ngFor="let pic of pictureList; let i=index"
																[class.active]="i === currentIndex"
																(click)="setCurrentPicture(i)">
			</span>
		</div> -->
    </div>
    <div class="col-2" id="arrow-right">
        <!-- <span class="icon-next" (click)="setNextPicture()"></span> -->
    </div>
</div>
