import { Component, Inject, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { SubjectService } from '../../services/subject.service';

import { ForumSubject } from '../../structures/subject';

@Component({
    selector: 'app-create-subject',
    templateUrl: './create-subject.component.html',
    styleUrls: ['./create-subject.component.scss']
})
export class CreateSubjectComponent implements OnInit, OnDestroy {
    subject: any;
    creating: boolean;

    headerLabel: string;
    footerLabel: string;

    private subscriptions = new Subscription();

    @Output() openErrorDialog: EventEmitter<string> = new EventEmitter();

    constructor(
        public dialogRef: MatDialogRef<CreateSubjectComponent>,
        private subjectService: SubjectService,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {}

    ngOnInit() {
        this.subject = {};
        this.subject.title = '';
        this.subject.instanceid = this.data.instanceid;
        this.subject.type = this.data.type;

        this.creating = false;

        this.headerLabel = 'Nouveau sujet';
        this.footerLabel = 'Créer le sujet';
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    canCreateSubject(): Boolean {
        return this.subject.title.length > 0;
    }

    /**
     * Crée/Met à jour le forum
     */
    createSubject() {
        if (this.canCreateSubject() && !this.creating) {
            this.creating = true;
            this.subscriptions.add(
                this.subjectService.createSubject(this.subject).subscribe(
                    (data: ForumSubject) => {
                        if (this.subject.type === 'forum') {
                            this.subjectService.emitCreatedSubjectInForum(data);
                        }
                        if (this.subject.type === 'theme') {
                            this.subjectService.emitCreatedSubjectInTheme(data);
                        }
                        if (this.subject.type === 'subtheme') {
                            this.subjectService.emitCreatedSubjectInSubtheme(data);
                        }
                        this.closeDialog();
                    },
                    (error: HttpErrorResponse) => {
                        this.openErrorDialog.emit(error.error.userMessage);
                    }
                )
            );
        }
    }

    /**
     * Ferme la fenêtre d'enregistrement à un forum
     */
    closeDialog() {
        this.dialogRef.close();
    }
}
